<template>
  <div class="timetracker">
    <TimeTrackerInfo ref="timeTrackerInfo"/>
    <TimeTrackerWrp :startFirstTimer="startFirstTimer" :stopFirstTimer="stopFirstTimer" />
  </div>
</template>

<script>
    import TimeTrackerInfo from '@/components/TimeTracker/TimeTrackerInfo'
    import TimeTrackerWrp from '@/components/TimeTracker/TimeTrackerWrp'

    export default {
        components: {
          TimeTrackerWrp, TimeTrackerInfo
        },
        methods: {
          startFirstTimer(){
            this.$refs.timeTrackerInfo.startTimer()
          },
          stopFirstTimer() {
            this.$refs.timeTrackerInfo.stopTimer()
          }
        }
    }
</script>

<style scoped>
  div{
    padding: 20px;
  }

  @media(max-width: 560px){
    div{
      padding: 20px 10px;
    }
  }
</style>
