<template>
    <div class="tracker-wrp__item item"  @mouseover="showCheckbox = true" @mouseleave="showCheckbox = false" >
        <div class="item__checkbox">
            <input class="modal__input" v-if="showCheckbox || isChecked" type="checkbox" :id="id" value="" @click="isChecked = !isChecked">
            <label class="modal__label" v-if="showCheckbox || isChecked" :for="id"></label>
            <svg  v-if="showCheckbox || isChecked" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666626 7.00008C0.666626 6.2637 1.26358 5.66675 1.99996 5.66675C2.73634 5.66675 3.33329 6.2637 3.33329 7.00008C3.33329 7.73646 2.73634 8.33341 1.99996 8.33341C1.26358 8.33341 0.666626 7.73646 0.666626 7.00008Z" fill="#E7E7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66663 7.00008C4.66663 6.2637 5.26358 5.66675 5.99996 5.66675C6.73634 5.66675 7.33329 6.2637 7.33329 7.00008C7.33329 7.73646 6.73634 8.33341 5.99996 8.33341C5.26358 8.33341 4.66663 7.73646 4.66663 7.00008Z" fill="#E7E7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666626 1.66659C0.666626 0.930206 1.26358 0.333252 1.99996 0.333252C2.73634 0.333252 3.33329 0.930206 3.33329 1.66659C3.33329 2.40296 2.73634 2.99992 1.99996 2.99992C1.26358 2.99992 0.666626 2.40296 0.666626 1.66659Z" fill="#E7E7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66663 1.66659C4.66663 0.930206 5.26358 0.333252 5.99996 0.333252C6.73634 0.333252 7.33329 0.930206 7.33329 1.66659C7.33329 2.40296 6.73634 2.99992 5.99996 2.99992C5.26358 2.99992 4.66663 2.40296 4.66663 1.66659Z" fill="#E7E7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666626 12.3333C0.666626 11.597 1.26358 11 1.99996 11C2.73634 11 3.33329 11.597 3.33329 12.3333C3.33329 13.0697 2.73634 13.6667 1.99996 13.6667C1.26358 13.6667 0.666626 13.0697 0.666626 12.3333Z" fill="#E7E7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66663 12.3333C4.66663 11.597 5.26358 11 5.99996 11C6.73634 11 7.33329 11.597 7.33329 12.3333C7.33329 13.0697 6.73634 13.6667 5.99996 13.6667C5.26358 13.6667 4.66663 13.0697 4.66663 12.3333Z" fill="#E7E7E8"/>
            </svg>
        </div>
        <div class="item__bg">
            <div class="item__wrp item__wrp-txt">
                <p class="item__info"  :class="projectColorClass">
                    <span @click="showStatusModal = !showStatusModal" class="item__info_status" :class="getStatusClass()"></span>
                    {{ taskTitle }}
                </p>
                <p class="item__title" @click="showSelectModal = !showSelectModal">{{ title }}</p>
            </div>
            <div class="item__wrp item__wrp-icons">
                <button class="tracker-info__tags" @click="showTagModal = !showTagModal"> 
                    <p>
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.225 13.4978L19.9345 14.2026L19.225 13.4978ZM13.5417 19.2187L12.8323 18.5139V18.5139L13.5417 19.2187ZM8.99502 19.2187L9.70445 18.5139L9.70445 18.5139L8.99502 19.2187ZM19.225 8.9211L19.9345 8.21633L19.225 8.9211ZM12.4824 2.13394L13.1919 1.42917V1.42917L12.4824 2.13394ZM2.12929 12.3076L1.41986 13.0124H1.41986L2.12929 12.3076ZM18.5156 12.793L12.8323 18.5139L14.2511 19.9235L19.9345 14.2026L18.5156 12.793ZM12.8323 18.5139C11.9678 19.3841 10.5689 19.3841 9.70445 18.5139L8.28559 19.9235C9.93217 21.581 12.6045 21.581 14.2511 19.9235L12.8323 18.5139ZM18.5156 9.62588C19.3837 10.4997 19.3837 11.9192 18.5156 12.793L19.9345 14.2026C21.5774 12.5488 21.5774 9.87014 19.9345 8.21633L18.5156 9.62588ZM11.773 2.83872L18.5156 9.62588L19.9345 8.21633L13.1919 1.42917L11.773 2.83872ZM11.7599 0.833252H3.36112V2.83325H11.7599V0.833252ZM9.70445 18.5139L2.83872 11.6028L1.41986 13.0124L8.28559 19.9235L9.70445 18.5139ZM0.833344 3.36103V11.5898H2.83334V3.36103H0.833344ZM2.83872 11.6028C2.83528 11.5994 2.83334 11.5947 2.83334 11.5898H0.833344C0.833344 12.1228 1.04419 12.6342 1.41986 13.0124L2.83872 11.6028ZM3.36112 0.833252C1.96507 0.833252 0.833344 1.96498 0.833344 3.36103H2.83334C2.83334 3.06955 3.06964 2.83325 3.36112 2.83325V0.833252ZM13.1919 1.42917C12.8129 1.04775 12.2975 0.833252 11.7599 0.833252V2.83325C11.7648 2.83325 11.7695 2.83522 11.773 2.83872L13.1919 1.42917ZM9.08334 7.79158C9.08334 8.50495 8.50504 9.08325 7.79168 9.08325V11.0833C9.60961 11.0833 11.0833 9.60952 11.0833 7.79158H9.08334ZM7.79168 9.08325C7.07831 9.08325 6.50001 8.50495 6.50001 7.79158H4.50001C4.50001 9.60952 5.97374 11.0833 7.79168 11.0833V9.08325ZM6.50001 7.79158C6.50001 7.07822 7.07831 6.49992 7.79168 6.49992V4.49992C5.97374 4.49992 4.50001 5.97365 4.50001 7.79158H6.50001ZM7.79168 6.49992C8.50504 6.49992 9.08334 7.07822 9.08334 7.79158H11.0833C11.0833 5.97365 9.60961 4.49992 7.79168 4.49992V6.49992Z"/>
                        </svg>
                    </p>
                </button>
                <button class="tracker-info__dollar" @click="isDollar = !isDollar"> 
                    <p v-bind:class="{active: isDollar}">
                        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5833 4.58341H4.70833C2.93642 4.58341 1.5 6.01983 1.5 7.79175C1.5 9.56366 2.93642 11.0001 4.70833 11.0001H9.29167C11.0636 11.0001 12.5 12.4365 12.5 14.2084C12.5 15.9803 11.0636 17.4167 9.29167 17.4167H2.41667M7 0.916748V21.0834" stroke-width="2" stroke-linecap="round"/>
                        </svg>
                    </p>
                </button>
                <button class="tracker-info__member" @click="showAdminModal = !showAdminModal"> 
                    <p>
                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3334 3.5V6.25M18.3334 6.25H21.0834M18.3334 6.25H15.5834M18.3334 6.25V9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.8006 10.375H4.78274C2.64758 10.375 0.916687 12.2405 0.916687 14.5417C0.916687 15.7843 1.84143 16.7917 2.98216 16.7917H12.6012C13.7419 16.7917 14.6667 15.7843 14.6667 14.5417C14.6667 12.2405 12.9358 10.375 10.8006 10.375Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11 4.41659C11 6.1885 9.56362 7.62492 7.79171 7.62492C6.01979 7.62492 4.58337 6.1885 4.58337 4.41659C4.58337 2.64467 6.01979 1.20825 7.79171 1.20825C9.56362 1.20825 11 2.64467 11 4.41659Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </p>
                </button>
                <div class="tracker-info__enter">
                    <svg class="tracker-info__calendar" @click="showCalendarModal = !showCalendarModal" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 1V5M14 1V5M19 9H1M5 13H6M9.5 13H10.5M14 13H15M5 17H10.5M14 17H15M3 21H17C18.0464 21 19 20.0042 19 19V5C19 3.99585 18.1517 3 17.1053 3H3C1.95357 3 1 3.99585 1 5V19C1 20.0042 1.95357 21 3 21Z" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="time-tracker-time-span">
                    <!-- input class="enter__input enter__input--begin" type="time" name="" id="" disabled="disabled" v-bind="startTime" -->{{ startTime }} |
                    <!--input class="enter__input enter__input--end" type="time" name="" id="" disabled="disabled" v-bind="endTime"--> {{ endTime }}
                    </span>
                </div>
                <div class="tracker-info__enter">
                    <p @click="showStartTimeModal = !showStartTimeModal" id="base-timer-label" class="base-timer__label" >{{ hours }} : {{ minutes }} : {{ seconds }} </p>
                    <button class="tracker-info__timer-btn tracker-info__timer-btn--on" v-if="timerOn"  @click="startTimer">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4167 9.13401C17.0834 9.51891 17.0834 10.4812 16.4167 10.8661L2.91675 18.6603C2.25008 19.0452 1.41675 18.5641 1.41675 17.7943L1.41675 2.2058C1.41675 1.436 2.25008 0.954875 2.91675 1.33978L16.4167 9.13401Z" stroke="black" stroke-width="2"/>
                        </svg>
                    </button>
                    <!-- button class="tracker-info__timer-btn tracker-info__timer-btn--off" v-else  @click="stopTimer">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.4167 9.13401C17.0834 9.51891 17.0834 10.4812 16.4167 10.8661L2.91675 18.6603C2.25008 19.0452 1.41675 18.5641 1.41675 17.7943L1.41675 2.2058C1.41675 1.436 2.25008 0.954875 2.91675 1.33978L16.4167 9.13401Z" stroke="black" stroke-width="2"/>
                        </svg>
                    </button -->
                    <svg @click="openMenu = !openMenu" class="tracker-info__dots" width="6" height="22" viewBox="0 0 6 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 10.9999C0.833252 9.8033 1.8033 8.83325 2.99992 8.83325C4.19654 8.83325 5.16658 9.8033 5.16658 10.9999C5.16658 12.1965 4.19654 13.1666 2.99992 13.1666C1.8033 13.1666 0.833252 12.1965 0.833252 10.9999Z" fill="black"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 2.33341C0.833252 1.1368 1.8033 0.166748 2.99992 0.166748C4.19654 0.166748 5.16658 1.1368 5.16658 2.33341C5.16658 3.53003 4.19654 4.50008 2.99992 4.50008C1.8033 4.50008 0.833252 3.53003 0.833252 2.33341Z" fill="black"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 19.6667C0.833252 18.47 1.8033 17.5 2.99992 17.5C4.19654 17.5 5.16658 18.47 5.16658 19.6667C5.16658 20.8633 4.19654 21.8333 2.99992 21.8333C1.8033 21.8333 0.833252 20.8633 0.833252 19.6667Z" fill="black"/>
                    </svg>
                    <MiniModal v-if="openMenu" :duplicateEntry="duplicateEntry" :deleteEntry="deleteEntry" @close="openMenu = false" />
                    <TotalTimeModal  v-if="showStartTimeModal" :updateMethod="udpateTracker" :totalTime="currentTotalTime" :date="startDate" :uk="uk"  @close="showStartTimeModal = false" />
                </div>
                <AdminModal  v-if="showAdminModal"  @close="showAdminModal = false" />
                <TagModal  v-if="showTagModal" :task_uk="item.tuk" :task_t="taskTitle" @close="showTagModal = false" />
                <CalendarModal   v-if="showCalendarModal" :date="startDate" :updateMethod="updateDateMethod" @close="showCalendarModal = false"/>
            </div>
        </div>
        <StatusModal  v-if="showStatusModal" :tuk="getCurrentTaskUk()" :taskTitle="item.task_t" :taskSuk="item.suk" :updateTaskStatus="updateTaskStatus"  @close="showStatusModal = false" @openCreateStatus="showCreateNewStatus = true"/>
        <SelectModal  v-if="showSelectModal"  @close="showSelectModal = false" @openMenu="showCreateNewModal = true"/>
        <CreateNewModal v-if="showCreateNewModal" @close="showCreateNewModal = false"/>
        <div class="bg" v-if="showCreateNewModal" @close="showCreateNewModal = false"></div>
        <CreateNewStatus v-if="showCreateNewStatus" @close="showCreateNewStatus = false"/>
        <div class="bg" v-if="showCreateNewStatus" @close="showCreateNewStatus = false"></div>
        <UserActionNotificationModal :message="notificationMessage" v-if="showUserNotificationModal" @close="showUserNotificationModal = false" />
    </div>
</template>

<script>
    import TagModal from '@/components/Modal/TagModal'
    import AdminModal from '@/components/Modal/AdminModal'
    import CalendarModal from '@/components/Modal/CalendarModal'
    import TotalTimeModal from '@/components/Modal/TotalTimeModal'
    import MiniModal from '@/components/Modal/MiniModal'
    import StatusModal from '@/components/Modal/StatusModal'
    import SelectModal from '@/components/Modal/SelectModal'
    import CreateNewModal from '@/components/Modal/CreateNewModal'
    import CreateNewStatus from '@/components/Modal/CreateNewStatus'
    import UserActionNotificationModal from '@/components/Modal/UserActionNotificationModal'
    import { useEntryStore } from '@/store/EntryStore'


    export default {
        props: ['item', 'startNewTimer', 'updateTimeEntryList', 'showTopMenu', 'scs'],
        components: {
            TagModal, AdminModal, CalendarModal, TotalTimeModal, MiniModal, StatusModal, SelectModal, CreateNewModal, CreateNewStatus, UserActionNotificationModal
        },
        data () {
            let totalTimes = this.item.tt.split(':')
            let entryStore = useEntryStore()
            return {
                showCheckbox:false,
                entryStore: entryStore,
                id: this.item.uk,
                info: this.item.t,
                title: this.item.t, 
                projectColor: this.item.cs,
                projectColorClass: 'item__info_' + this.item.cs,
                taskTitle: this.item.task_t,
                startDate: this.item.sd,
                startTime: this.item.st, 
                endTime: this.item.et,
                isChecked: this.item.isChecked? this.item.isChecked : false ,
                totalTime: this.item.tt, 
                uk: this.item.uk, 
                showCreateNewStatus: false,
                showCreateNewModal: false,
                showTopModal: false,
                openMenu: false,
                groupByDate: true,
                showAddTaskModal: false,
                isDollar: this.item.ib ? this.item.ib == 1 : false,
                showTagModal: false,
                showStatusModal: false,
                showAdminModal: false,
                showCalendarModal: false,
                showStartTimeModal: false,         
                showFirst: true,
                timePassed: 0,
                hours: totalTimes[0],
                minutes: totalTimes[1],
                seconds: totalTimes[2],
                timerOn: true,
                stopTime: 0,
                currentTime: 0,
                currentTotalTime: this.item.tt,
                timer: null,
                showSelectModal: false,  
                showUserNotificationModal: false              
            }
        },
        watch: {
            isDollar(){
                this.entryStore.update(this.item.uk, { ib: this.isDollar ? 1 : 0})
            },
            isChecked(){
                this.showTopMenu(this.isChecked)
            }
        },
        methods:{
            getStatusClass(){
                return 'item__info_status_' + this.scs
            },
            getCurrentTaskUk(){
                return this.item.tuk
            },
            updateTaskStatus(tuk, suk, scs){
                this.entryStore.updateEntryStatus(tuk, suk, scs)
                this.showUserNotificationModalMethod('Status for task ' + this.item.task_t + ' was changed')
                this.item.scs = scs
                this.item.suk = suk
                this.statusColorClass = 'item__info_status_' + scs
                this.updateTimeEntryList()
            },
            showUserNotificationModalMethod(message){
                this.notificationMessage = message
                this.showUserNotificationModal = true
                setTimeout(this.closeUserNotificationModal, 2000);
            },
            closeUserNotificationModal(){
                this.showUserNotificationModal = false;
            },
            updateDateMethod(date){
                date = this.entryStore.getDateValue(date)
                this.startDate = date
                this.entryStore.update(this.item.uk, { sd: date} )
                this.updateTimeEntryList()
            },
            duplicateEntry(){
                this.entryStore.duplicate(this.item.uk)
            },
            deleteEntry(){
                if ( confirm('Are you sure you want to remove ' + this.item.t))
                    this.entryStore.remove(this.item)
            },
            udpateTracker(totalTime){
                let totalTimes = totalTime.split(':')
                this.currentTotalTime = totalTime
                this.hours = ("0" + totalTimes[0]).slice(-2)
                this.minutes = ("0" + totalTimes[1]).slice(-2)
                this.seconds = ("0" + totalTimes[2]).slice(-2)
                let newEndTimeTicks = (new Date(this.startDate + ' ' + this.startTime)).getTime() +  
                    (totalTimes[0] * 60 * 60 * 1000) +  
                    (totalTimes[1] * 60 * 1000) +  
                    (totalTimes[2] * 1000)
                let newEndTime =  new Date(newEndTimeTicks)
                this.endTime = ("0" + newEndTime.getHours()).slice(-2) + ':' + ("0" + newEndTime.getMinutes()).slice(-2) + ':' + ("0" + newEndTime.getSeconds()).slice(-2) 
            },
            formatTime(time){
                this.minutes = Math.floor(time / 60);
                this.seconds = time % 60;
                if (this.seconds < 10) {
                    this.seconds = `0${this.seconds}`;
                }
                if (this.minutes < 10){
                    this.minutes = `0${this.minutes}`;
                }
            },
            startTimer() {
                /*this.timer = setInterval(() => {
                    this.currentTime++
                    this.formatTime(this.currentTime);
                    }, 1000)
                this.timerOn = false*/
                this.startNewTimer(this.item)
            },
            stopTimer() {
                /*clearTimeout(this.timer)
                this.timerOn = true;*/
            },
        },
        destroyed() {
            this.stopTimer()
        },
    };

</script>

<style scoped>

    .tracker-wrp__item{
        position: relative;
    }

    p{
        margin: 0;
        padding: 0;
    }

    .tracker-wrp__item{
        display: flex;
        flex-direction: row;
        margin-left: -32px;
        box-sizing: border-box;
    }
    .item__bg .item__wrp  .item__info{
        min-width: 160px;
    }
    span.time-tracker-time-span{
        min-width:160px;
    }
    .item__info{
        padding-left: 45px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
    }

    .item__info span{
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 16px;
        background-color:#9DE8FF;
        left: 5px;
        border-radius: 50%;
        cursor: pointer;
    }
    
    .item__info:after{
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        background-color:#9F9DFF;
        left: 30px;
        top: 4px;
        border-radius: 50%;
        border-left: 1px solid #F6F7F9;
    }

    .item__wrp{
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 5px;
    }

    .item__wrp-icons{
        margin-left: auto;
        width: 610px;
        position: relative;
    }

    .item__checkbox{
        width: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 21px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        cursor: pointer;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: -10px;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .item__title{
        font-size: 14px;
        font-weight: 500;
        margin-left: 35px;
        cursor: pointer;
        min-width: 300px;
    }

    button{
        cursor: pointer;
    }

    input:invalid{
        border: 1px solid red;
    }


    .tracker-info__tab.item__wrp-icons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 0 0 10px 0;
    }


    .item__wrp-icons button{
        border-right: 2px solid #F2F3F6;
    }

    .item__wrp-icons .tracker-info__timer-btn{
        border: 0;
        height: 42px;
        width: 42px;
        border-radius: 50%;
        text-align: center;
        line-height: 10px;
    }
    
    .item__wrp-icons .tracker-info__timer-btn:hover{
        background: #9DE8FF4D;
    }

    .item__wrp-icons button p{
        width: 42px;
        height: 42px;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
    }

    .item__wrp-icons button:active p{
        background-color: #9CEDFF;
    }

    .item__wrp-icons button:first-child svg{
        fill: #606569;
    }

    .item__wrp-icons button:first-child:active svg{
        fill: #222222;
    }

    .item__wrp-icons button:nth-child(n+2) svg{
        stroke: #606569;
    }

    .item__wrp-icons button:nth-child(n+2):active svg{
        stroke: #222222;
    }

    .active{
        background-color: #9CEDFF;
    }

    .item__wrp-icons  button:nth-child(2) .active svg{
        stroke: #222222;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
    }

    .tracker-info__enter{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tracker-info__enter svg{
        align-self: center;
        cursor: pointer;
    }

    .enter__input{
        height: 42px;
        border: none;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        position: relative;
    }

    .enter__input--begin:after{
        position: absolute;
        content: "";
        width: 2px;
        height: 42px;
        background-color: #F2F3F6;
        bottom: 0px;
        right: 12px;
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
        background: none;
    }

    .enter__input-time{
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 4px;
        width: 121px;
        height: 42px;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        align-self: center;
        text-align: center;
        color: #222222;
        margin-right: 20px;
    }

    
    .base-timer__label{
        display: block;
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        width: 120px;
        text-align: center;
        cursor: pointer;
    }

    .item__wrp-icons .tracker-info__timer-btn{
        margin-left: 20px;
    }

    .tracker-info__timer-btn--off{
        background: #9DE8FF;
    }

    .tracker-info__calendar{
        padding: 20px;
    }

    .tracker-info__dots{
        padding: 0 25px;
        cursor: pointer;
    }

    .item__bg{
        display: flex;
        flex-direction: row;
        width: 100%;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
        background-color: #fff;
    }

    .item__checkbox svg{
        padding-right: 3px;
        cursor: pointer;
    }

    @media(max-width: 1403px){
        .item__bg{
            flex-wrap: wrap;
            height: 100%;
        }

        .item__wrp-txt{
            padding: 15px 10px;
        }

        .close .item__bg{
            flex-wrap: nowrap;
            height: auto;
        }

        .close .item__wrp-txt{
            padding: 0;
        }
    }

    @media(max-width: 1199px){
        .close .item__bg{
            flex-wrap: wrap;
            height: 100%;
        }

        .close .item__wrp-txt{
            padding: 15px 10px;
        }
    }

    @media(max-width: 950px){
        .item__wrp-icons{
            flex-wrap: wrap;
            width: 100%;
            justify-content: flex-end;
        }
    }

    @media(max-width: 615px) {
        .item__title{
            width: 100%;
            min-width: auto;
            padding-top: 10px;
        }

        .item__wrp-txt{
            flex-wrap: wrap;
        }
    }

    @media(max-width: 506px){
        .item__wrp-icons button.tracker-info__member{
            border-right: none;
        }
    }

    @media(max-width: 362px){
        .tracker-info__enter{
            flex-wrap: wrap;
            justify-content: space-between;
            padding-bottom: 20px;
        }

        .base-timer__label{
            width: 100%;
            padding-bottom: 5px;
        }
    }

</style>