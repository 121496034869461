<template>
    <div class="time-modal" v-click-outside="onClickOutside">
        <p class="time-modal__title">Start time</p>
        <input type="text" placeholder="00:00:00" v-model="currentTotalTime" @change="changeTotalTime">
        <p class="time-modal__today">{{ currentDate }}</p>
    </div>
</template>
<script>
    import vClickOutside from 'v-click-outside'
    import { useEntryStore } from '@/store/EntryStore'


    export default {
        props: ['totalTime', 'date', 'uk', 'updateMethod'],
        data() {
            let entryStore = useEntryStore()
            return {
                currentDate: entryStore.getDateString(this.date),
                entryStore: entryStore,
                currentTotalTime: this.totalTime
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            changeTotalTime(){
                this.entryStore.update(this.uk, { tt: this.currentTotalTime} ).then(() =>{
                    this.updateMethod(this.currentTotalTime)
                })
            }
        }
    }
</script>
<style scoped>
    .time-modal{
        position: absolute;
        width: 350px;
        height: 80px;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
        justify-content:  space-around;
        align-items: center;
        border: 1px solid #E7E7E8;
        padding: 10px;
        box-sizing: border-box;
        top: 75px;
        right: 90px;
        z-index: 13;
    }

    .item__wrp-icons .time-modal{
        top: 60px;
        right: 60px;
    }

    .time-modal__title{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .time-modal input{
        border: 2px solid #E7E7E8;
        border-radius: 4px;
        height: 42px;
        width: 121px;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        font-size: 16px;
    }

    .time-modal__today{
        font-size: 16px;
        font-weight: 600;
    }

</style>