<template>
        <div class="team-members__mini-modal"  v-click-outside="onClickOutside">
            <p @click="duplicateMethod">Duplicate</p>
            <p @click="deleteMethod">Delete</p>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    
    export default {
        props: ['duplicateEntry', 'deleteEntry', 'uk'],
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            duplicateMethod(){
                this.duplicateEntry()
                this.$emit('close')
            },
            deleteMethod(){
                this.deleteEntry()
                this.$emit('close')
            }
        }
    };
</script>

<style scoped>
    .team-members__mini-modal{
        height: 78px;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        right: 40px;
        bottom: -55px;
        z-index: 13;
        font-size: 14px;
        font-weight: 500;
    }

    .project-tasks__wrp .team-members__mini-modal{
        right: 30px;
        bottom: -45px;
    }

    .team-members__mini-modal p{
        cursor: pointer;
        margin: 15px 0;
        text-align: start;
    }
</style>