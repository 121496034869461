<template>
        <div class="status-modal" v-click-outside="onClickOutside">
            <h3 class="status-modal--title">
                Create new status
            </h3>
            <div class="status-modal__div">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="status-modal__wrp">
                    <div class="status-modal__left">
                        <p class="left__p" @click="toogleWorkspace" v-bind:class="{ active: isActiveCustom }">Use Space statuses</p>
                        <p class="left__p"  @click="toogleWorkspace" v-bind:class="{ active: isActiveUse }">Custom</p>
                        <p class="left__template">
                            <span>Templates (7)</span>
                            <svg @click="isAddTemplate = !isAddTemplate" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.6667 7.99992C13.6667 11.1295 11.1296 13.6666 8 13.6666V15.6666C12.2342 15.6666 15.6667 12.2341 15.6667 7.99992H13.6667ZM8 13.6666C4.87039 13.6666 2.33333 11.1295 2.33333 7.99992H0.333332C0.333332 12.2341 3.76582 15.6666 8 15.6666V13.6666ZM2.33333 7.99992C2.33333 4.87031 4.87039 2.33325 8 2.33325V0.333252C3.76582 0.333252 0.333332 3.76574 0.333332 7.99992H2.33333ZM8 2.33325C11.1296 2.33325 13.6667 4.87031 13.6667 7.99992H15.6667C15.6667 3.76574 12.2342 0.333252 8 0.333252V2.33325Z" fill="#606569"/>
                                <path d="M7 10.6666C7 11.2189 7.44771 11.6666 8 11.6666C8.55228 11.6666 9 11.2189 9 10.6666H7ZM9 5.33325C9 4.78097 8.55228 4.33325 8 4.33325C7.44771 4.33325 7 4.78097 7 5.33325H9ZM10.6667 8.99992C11.219 8.99992 11.6667 8.5522 11.6667 7.99992C11.6667 7.44763 11.219 6.99992 10.6667 6.99992V8.99992ZM5.33333 6.99992C4.78105 6.99992 4.33333 7.44763 4.33333 7.99992C4.33333 8.5522 4.78105 8.99992 5.33333 8.99992V6.99992ZM9 10.6666V7.99992H7V10.6666H9ZM9 7.99992V5.33325H7V7.99992H9ZM8 8.99992H10.6667V6.99992H8V8.99992ZM8 6.99992H5.33333V8.99992H8V6.99992Z" fill="#606569"/>
                            </svg>
                        </p>
                        <input type="text" v-if="isAddTemplate" placeholder="Add template...">
                        <ul class="left__list" v-bind:class="{ short: isAddTemplate }">
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                            <li>Use Space statuses</li>
                        </ul>
                    </div>
                    <div class="status-modal__right">
                        <div class="status-modal__status-item">
                            <p class="status-modal__status-label">Active statuses</p>
                            <div class="status-modal__status-wrp disable">
                                <p class="status-modal__status-input">
                                    <span>Open</span>
                                    <svg @click="toogleMenu1" width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 7.00008C0.666672 6.2637 1.26363 5.66675 2.00001 5.66675C2.73638 5.66675 3.33334 6.2637 3.33334 7.00008C3.33334 7.73646 2.73638 8.33341 2.00001 8.33341C1.26363 8.33341 0.666672 7.73646 0.666672 7.00008Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 1.66659C0.666672 0.930206 1.26363 0.333252 2.00001 0.333252C2.73638 0.333252 3.33334 0.930206 3.33334 1.66659C3.33334 2.40296 2.73638 2.99992 2.00001 2.99992C1.26363 2.99992 0.666672 2.40296 0.666672 1.66659Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 12.3333C0.666672 11.597 1.26363 11 2.00001 11C2.73638 11 3.33334 11.597 3.33334 12.3333C3.33334 13.0697 2.73638 13.6667 2.00001 13.6667C1.26363 13.6667 0.666672 13.0697 0.666672 12.3333Z" fill="#606569"/>
                                    </svg>
                                </p>
                                <div class="status__menu" v-if="isOpenMenu1">
                                    <p>Rename</p>
                                    <div>
                                        <p for="status-modal__color" @click="showSelectColorModal = !showSelectColorModal">Change Color</p>
                                        <SelectColorModal  v-if="showSelectColorModal"  @close="showSelectColorModal = false" />
                                    </div>
                                </div>
                            </div>
                            <div class="status-modal__status-wrp">
                                <p class="status-modal__status-input">
                                    <span>In progress</span>
                                    <svg @click="toogleMenu2" width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 7.00008C0.666672 6.2637 1.26363 5.66675 2.00001 5.66675C2.73638 5.66675 3.33334 6.2637 3.33334 7.00008C3.33334 7.73646 2.73638 8.33341 2.00001 8.33341C1.26363 8.33341 0.666672 7.73646 0.666672 7.00008Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 1.66659C0.666672 0.930206 1.26363 0.333252 2.00001 0.333252C2.73638 0.333252 3.33334 0.930206 3.33334 1.66659C3.33334 2.40296 2.73638 2.99992 2.00001 2.99992C1.26363 2.99992 0.666672 2.40296 0.666672 1.66659Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 12.3333C0.666672 11.597 1.26363 11 2.00001 11C2.73638 11 3.33334 11.597 3.33334 12.3333C3.33334 13.0697 2.73638 13.6667 2.00001 13.6667C1.26363 13.6667 0.666672 13.0697 0.666672 12.3333Z" fill="#606569"/>
                                    </svg>
                                </p>
                                <div class="status__menu" v-if="isOpenMenu2">
                                    <p>Rename</p>
                                    <div>
                                        <p for="status-modal__color" @click="showSelectColorModal = !showSelectColorModal">Change Color</p>
                                        <SelectColorModal  v-if="showSelectColorModal"  @close="showSelectColorModal = false" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <p class="status-modal__add-p">
                            <input class="status-modal__status-input new" placeholder="New status">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.3333 10.0001C17.3333 14.0502 14.0501 17.3334 10 17.3334V19.3334C15.1547 19.3334 19.3333 15.1547 19.3333 10.0001H17.3333ZM10 17.3334C5.94992 17.3334 2.66667 14.0502 2.66667 10.0001H0.666672C0.666672 15.1547 4.84535 19.3334 10 19.3334V17.3334ZM2.66667 10.0001C2.66667 5.94999 5.94992 2.66675 10 2.66675V0.666748C4.84535 0.666748 0.666672 4.84542 0.666672 10.0001H2.66667ZM10 2.66675C14.0501 2.66675 17.3333 5.94999 17.3333 10.0001H19.3333C19.3333 4.84542 15.1547 0.666748 10 0.666748V2.66675Z" fill="#606569"/>
                                    <path d="M7.64313 9.47072C7.24163 9.0915 6.60872 9.10956 6.22949 9.51106C5.85027 9.91257 5.86833 10.5455 6.26983 10.9247L7.64313 9.47072ZM9.04887 12.174L8.36222 12.901C8.55677 13.0847 8.81685 13.1828 9.08429 13.1734C9.35173 13.1639 9.60421 13.0476 9.78526 12.8506L9.04887 12.174ZM13.7798 8.50273C14.1535 8.09603 14.1267 7.46344 13.72 7.08978C13.3133 6.71613 12.6807 6.74292 12.307 7.14961L13.7798 8.50273ZM6.26983 10.9247L8.36222 12.901L9.73552 11.447L7.64313 9.47072L6.26983 10.9247ZM9.78526 12.8506L13.7798 8.50273L12.307 7.14961L8.31248 11.4974L9.78526 12.8506Z" fill="#606569"/>
                                </svg>
                        </p>
                        <div class="status-modal__status-item">
                            <p class="status-modal__status-label">Done statuses</p>
                            <p class="status-modal__info">Move statuses here to consider task Done. <span>Learn More</span></p>
                        </div>
                        <div class="status-modal__status-item">
                            <p class="status-modal__status-label">Closed statuses</p>
                            <div class="status-modal__status-wrp">
                                <p class="status-modal__status-input">
                                    <span>Complete</span>
                                    <svg @click="toogleMenu3" width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 7.00008C0.666672 6.2637 1.26363 5.66675 2.00001 5.66675C2.73638 5.66675 3.33334 6.2637 3.33334 7.00008C3.33334 7.73646 2.73638 8.33341 2.00001 8.33341C1.26363 8.33341 0.666672 7.73646 0.666672 7.00008Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 1.66659C0.666672 0.930206 1.26363 0.333252 2.00001 0.333252C2.73638 0.333252 3.33334 0.930206 3.33334 1.66659C3.33334 2.40296 2.73638 2.99992 2.00001 2.99992C1.26363 2.99992 0.666672 2.40296 0.666672 1.66659Z" fill="#606569"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.666672 12.3333C0.666672 11.597 1.26363 11 2.00001 11C2.73638 11 3.33334 11.597 3.33334 12.3333C3.33334 13.0697 2.73638 13.6667 2.00001 13.6667C1.26363 13.6667 0.666672 13.0697 0.666672 12.3333Z" fill="#606569"/>
                                    </svg>
                                </p>
                                <div class="status__menu" v-if="isOpenMenu3">
                                    <p>Rename</p>
                                    <div>
                                        <p for="status-modal__color" @click="showSelectColorModal = !showSelectColorModal">Change Color</p>
                                        <SelectColorModal  v-if="showSelectColorModal"  @close="showSelectColorModal = false" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="buttons">
                    <button class="status-modal__create">
                        Create
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import SelectColorModal from '@/components/Modal/SelectColorModal'


    
    export default {
        directives: {
        clickOutside: vClickOutside.directive
        },
        components: {
            SelectColorModal
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            toogleWorkspace(){
                this.isActiveCustom = !this.isActiveCustom
                this.isActiveUse = !this.isActiveUse
            },
            toogleMenu1(){
                    this.isOpenMenu1 = !this.isOpenMenu1;
                    this.isOpenMenu2 = false;
                    this.isOpenMenu3 = false;
            },
            toogleMenu2(){
                    this.isOpenMenu2 = !this.isOpenMenu2;
                    this.isOpenMenu1 = false;
                    this.isOpenMenu3 = false;
            },
            toogleMenu3(){
                    this.isOpenMenu3 = !this.isOpenMenu2;
                    this.isOpenMenu1 = false;
                    this.isOpenMenu2 = false;
            }
        },
        data(){
            return{
                isAddTemplate: false,
                isActiveCustom: true,
                isActiveUse: false,
                isOpenMenu1: false,
                isOpenMenu2: false,
                isOpenMenu3: false,
                showSelectColorModal: false,
            }
        }
    };
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }

    .status-modal{
        width: 564px;
        height: 580px;
        background-color: #fff;
        position: fixed;
        top: 5%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 30;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .status-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row;
    }

    .buttons button{
        margin-right: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .status-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .status-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .status-modal__div{
        display: flex;
        flex-direction: column;
    }

    .status-modal__wrp{
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid #F2F3F6;
        height: 425px;
    }

    .status-modal__left{
        width: calc(50% - 10px);
        padding-top: 10px;
        margin-right: 10px;
    }

    .status-modal__right{
        width: calc(50% - 10px);
        margin-left: 10px;
    }

    .status-modal__color{
        border: none;
        background: #fff;
        width: 26px;
        height: 30px;
        border-radius: 50%;
        padding: 0;
        margin-right: 5px;
        cursor: pointer;
        visibility: hidden;
    }

    .left__p{
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        cursor: pointer;
    }

    .active{
        background-color: #9CEDFF;
    }

    .left__template{
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        color: #606569;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        width: 100%;
    }

    .left__template svg{
        margin-left: auto;
        margin-right: 15px;
        cursor: pointer;
    }

    .status-modal__left input{
        width: 100%;
        height: 42px;
        margin-top: 10px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 15px;
        font-family: 'Montserrat', sans-serif;
    }

    .left__list{
        padding-left: 10px;
        list-style: none;
        max-height: 260px;
        overflow-y: auto;
        margin-top: 20px;
    }

    .left__list li{
        padding: 7px 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        cursor: pointer;
    }

    .short{
        max-height: 220px;
    }

    .status-modal__status-wrp{
        position: relative;
        padding: 10px 0;
    }

    .status-modal__status-label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .status-modal__status-input{
        width: 100%;
        height: 42px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 10px 10px 15px;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: 600;
        
    }

    .status-modal__status-input svg{
        margin-left: auto;
        cursor: pointer;
        padding-top: 2px;
        padding: 2px 5px 0 5px;
    }

    .status-modal__status-input span{
        padding-left: 25px;
        position: relative;
    }

    .status-modal__status-input span:before{
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #E058B2;
        top: 0;
        left: 0;
    }

    .status__menu{
        position: absolute;
        width: auto;
        background-color: #fff;
        padding: 10px;
        border: 1px solid #F6F7F9;
        top: 42px;
        right: 10px;
        z-index: 31;
    }

    .status__menu p{
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        padding: 5px 0;
    }

    .status__menu label{
        cursor: pointer;
    }

    .disable .status-modal__status-input span{
        color: #E7E7E8;
    }

    .disable .status-modal__status-input span:before{
        background-color: #E7E7E8;
    }

    .status-modal__add-btn{
        margin-top: 10px;
        width: 100%;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius: 30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .status-modal__add-btn svg{
        fill: #222222;
    }

    .status-modal__add-btn span{
        padding-left: 20px;
    } 

    .status-modal__add-p{
        position: relative;
        margin: 10px 0;
    }

    .status-modal__status-item{
        margin-top: 20px;
    }

    .status-modal__add-p svg{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .new{
        color: #222222;
        cursor: pointer;
    }

    .status-modal__info{
        font-size: 12px;
        font-weight: 600;
        margin-top: 10px;
    }

    .status-modal__info span{
        color: #606569;
        text-decoration: underline;
        cursor: pointer;
    }

    @media(max-width: 500px){
        .status-modal{
            overflow-y: auto;
        }
        .status-modal__wrp{
            flex-direction: column;
            height: auto;
        }
        .status-modal__left{
            width: 100%;
        }

        .status-modal__right{
            width: 100%;
        }
    }

</style>