<template>
        <div class="tag-modal" id="tag-modal" v-click-outside="onClickOutside">
            Tags for <strong>{{ task_t }}</strong>
            <p class="tag-modal__p">
                <span v-for="item in checkedTags" :key="item"> {{item}} </span>
                <input type="text" placeholder="Add/Find tag">
                <svg class="clickable-svg" @click="showEditTagModal = !showEditTagModal" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5 9C15.5 12.5899 12.5899 15.5 9 15.5V17.5C13.6944 17.5 17.5 13.6944 17.5 9H15.5ZM9 15.5C5.41015 15.5 2.5 12.5899 2.5 9H0.5C0.5 13.6944 4.30558 17.5 9 17.5V15.5ZM2.5 9C2.5 5.41015 5.41015 2.5 9 2.5V0.5C4.30558 0.5 0.5 4.30558 0.5 9H2.5ZM9 2.5C12.5899 2.5 15.5 5.41015 15.5 9H17.5C17.5 4.30558 13.6944 0.5 9 0.5V2.5Z" fill="#9CEDFF"/>
                    <path d="M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12H8ZM10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6H10ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8V10ZM6 8C5.44772 8 5 8.44772 5 9C5 9.55228 5.44772 10 6 10V8ZM10 12V9H8V12H10ZM10 9V6H8V9H10ZM9 10H12V8H9V10ZM9 8H6V10H9V8Z" fill="#9CEDFF"/>
                </svg>
            </p>
            <div class="tag-modal__wrp">
                <div class="tag-list-item"  v-for="item in tagStore.allTags" :key="item.uk">
                    <input class="modal__input" type="checkbox" :id="item.uk" :checked="item.checked == 1" :value="item.t" @click="checkTag(item)">
                    <label class="modal__label" for="item.uk"  @click="checkTag(item)"> {{ item.t }}</label>
                </div>
            </div>
            <AddTagModal v-if="showEditTagModal" @close="showEditTagModal = false"/>
            <div class="bg" v-if="showEditTagModal" @close="showEditTagModal = false"></div>
            <UserActionNotificationModal :message="notificationMessage" v-if="showUserNotificationModal" @close="showUserNotificationModal = false" />
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import AddTagModal from '@/components/Modal/AddTagModal'
    import UserActionNotificationModal from '@/components/Modal/UserActionNotificationModal'
    import { useTagStore } from '@/store/TagStore'
    import { useTaskStore } from '@/store/TaskStore'
    
    export default {
        props:['task_uk', 'task_t'],
        data() {
            const tagStore = useTagStore()
            const taskStore = useTaskStore()
            return {
                tagStore: tagStore,
                taskStore: taskStore,
                showEditTagModal: false,
                checkedTags: [],
                showUserNotificationModal: false,
                notificationMessage: ''
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            showUserNotificationModalMethod(message){
                this.notificationMessage = message
                this.showUserNotificationModal = true
                setTimeout(this.closeUserNotificationModal, 1000);
            },
            closeUserNotificationModal(){
                this.showUserNotificationModal = false;
            },
            updateCheckedTags(){
                this.checkedTags = []
                for ( let i = 0; i < this.tagStore.allTags.length; i ++) {
                    if ( this.tagStore.allTags[i].checked == 1 ){
                        this.checkedTags.push(this.tagStore.allTags[i].t)
                    }
                }
                this.$forceUpdate()
            },
            checkTag(item) {
                if( item.checked == 0 || item.checked == null ){
                    item.checked = 1
                    this.checkedTags.push(item.t)
                } else {
                    item.checked = 0
                }
                this.updateCheckedTags()
                this.taskStore.setTaskTag(this.task_uk, item.uk, item.checked ? 1 : 0)
                this.showUserNotificationModalMethod('Tags successfully updated for ' + this.task_t)
            },
            onClickOutside () {
                this.$emit('close')
            }
        },
        created() {
            this.tagStore.listingTask(this.task_uk).then(() => {
                this.updateCheckedTags(0)
            })
        },  
        components: {
            AddTagModal, UserActionNotificationModal
        }
    }
</script>

<style scoped>

    .top-modal .tag-modal{
        right: 0;
        left: 450px;
        top: 50px;
    }
    .tag-list-item{
        height: 25px;
    }
    .tag-modal{
        background-color: #fff;
        color: #222222;
        box-sizing: border-box;
        position: absolute;
        width: 330px;
        height: 205px;
        padding: 10px 15px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
        right: 286px;
        top: 70px;
        z-index: 10;
    }

    .second .tag-modal{
        right: 470px;
    } 

    .item__wrp-icons .tag-modal{
        top: 60px;
        right: 340px;
    }

    .tag-modal__wrp{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 135px;
        position: absolute;
        width: 93%;
        background-color: #fff;
        z-index: 10;
        box-sizing: border-box;
    }

    .tag-modal__p{
        width: 100%;
        margin: 0;
        padding: 6px 15px;
        height: auto;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        overflow-y: auto;
        min-height: 42px;
        display: block;
        flex-direction: row;
        align-items: center;
        overflow-x: hidden;
    }

    .tag-modal__p span{
        display: inline-block;
        padding: 1px 5px;
        border: 0.5px solid #eaeaea;
        border-radius: 5px;
        margin: 1px 2px;
    }

    .tag-modal__p svg{
        margin-left: auto;
        float:right;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 30px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        cursor: pointer;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0px;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        padding-top: 10px;
        color: #BABCBF;
    }

    .tag-modal__p input{
        border: none;
        font-family: Montserrat;
    }

    .tag-modal__p span ~ input{
        display: none;
    }

    .no-tags{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
    }

    @media(max-width: 450px) {
        .tag-modal{
            width: 70vw;
        }
    }

    @media(max-width: 1403px){
        .first .tag-modal{
            top: 155px;
        }

        .second .tag-modal{
            top: 155px;
        }
    }

    @media(max-width:1199px){
        .first .tag-modal{
            top: 155px;
        }

        .second .tag-modal{
            top: 155px;
        }
    }

    @media(max-width:999px){
        .first .tag-modal{
            top: 195px;
            left: 0;
        }

        .second .tag-modal{
            right: 70px;
            top: 165px;
        }

        .close .second .tag-modal{
            top: 195px;
            right: 400px;
        }

        .tracker-wrp__item .tag-modal{
            right: 280px;
        }
    }

    @media (max-width:950px){
        .tracker-wrp__item .tag-modal{
            right: 150px;
        }

        .close .tracker-wrp__item .tag-modal{
            right: 280px;
        }
    }

    @media(max-width:800px){
        .close .second .tag-modal{
            right: 70px;
            top: 165px;
        }

        .tracker-wrp__item .tag-modal{
            right: 280px;
        }
    }

    @media(max-width: 744px){
        .tracker-wrp__item .tag-modal{
            right: 100px;
        }

        .close .tracker-wrp__item .tag-modal{
            right: 100px;
        }
    }

    @media(max-width:710px){
        .first .tag-modal{
            right: 0;
            left: 0;
        }

        .close .second .tag-modal {
            right: 70px;
            top: 195px;
        }
    }

    @media(max-width: 615px){
        .first .tag-modal{
            top: 205px;
        }

        .second .tag-modal{
            top: 205px;
        }

        .tracker-wrp__item .tag-modal{
            right: 20px;
        }

        .close .tracker-wrp__item .tag-modal{
            right: 20px;
        }
    }

    @media(max-width:480px){
        .second .tag-modal{
            right: 0;
        }

        .close .second .tag-modal{
            right: 0;
        }
    }

</style>