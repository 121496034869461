import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useTagStore = defineStore({
    id: 'TagStore',
    state(){ 
        return {
            list: [],
            filter: 'active',//'archived','all'
            search: '',
            errors: [] 
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        allTags: function(state){
            console.log('All tag count: ' + state.list.length)
            return state.list
        },
        tags: function(state){
            let archivedParam = -1;
            if ( state.filter == 'active') archivedParam = 0;
            else if ( state.filter == 'archived' ) archivedParam = 1;
            return state.list.filter((el) => { 
                return el.t.toLowerCase().indexOf(state.search.toLowerCase()) != -1
                    && (el.a == archivedParam || archivedParam == -1)
                });
        },
        filterValue: function(state){
            return state.filter
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        async changeSearch(search){
            this.$patch((state) => {
                state.search = search;
            })
        },
        async changeFilter(filter){
            this.$patch((state) => {
                state.filter = filter;
            })
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/tags');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        
        async listingTask(task_uk){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/tags/tasks/'+task_uk);
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        async insert(dataInsert){
            // data should contain title field
            if ( dataInsert['title'].length == 0) return;
            console.log(dataInsert['title'])
            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    console.log('Insert Method: ' + dataInsert.title)
                    const response = await ApiCall.post(
                        'ws/{wk}/tags',
                        {
                            title: dataInsert.title
                        }
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(tag, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.put(
                    'ws/{wk}/tags/' +tag.uk,
                    form
                )
                for(let i=0;i<this.list.length;i++){
                    if ( this.list[i].uk == tag.uk) {
                        this.$patch((state) => {
                            state.list[i].t = form.title
                        })
                        break
                    }
                }
                return response
            }
        },
        async remove(tag) {
            ApiCall.options().then(response => {
                ApiCall.delete('ws/{wk}/tags/' + tag.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    else {
                        for(let i=0;i<this.list.length;i++){
                            if ( this.list[i].uk == tag.uk) {
                                this.$patch((state) => {
                                    state.list.splice(i, 1)
                                })
                                break
                            }
                        }
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            })  
        }
    }
})