<template>
        <div class="admin-modal" v-click-outside="onClickOutside">
            <p class="admin-modal__p" >
                <input type="text" placeholder="Enter name..." :value="checkedAdmin">
            </p>
            <div class="admin-modal__wrp">
                <input class="modal__input" type="radio" id="mb1" value="Member 1" name="status" v-model="checkedAdmin">
                <label class="modal__label" for="mb1">Member 1</label>
                <input class="modal__input" type="radio" id="mb2" value="Member 2" name="status" v-model="checkedAdmin">
                <label class="modal__label" for="mb2">Member 2</label>
                <input class="modal__input" type="radio" id="mb3" value="Member 3" name="status" v-model="checkedAdmin">
                <label class="modal__label" for="mb3">Member 3</label>
                <input class="modal__input" type="radio" id="mb4" value="Member 4" name="status" v-model="checkedAdmin">
                <label class="modal__label" for="mb4">Member 4</label>
            </div>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'


    export default {
        data() {
            return {
                checkedAdmin: '',
                hasStatuses: true
            }
        },
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
    .admin-modal{
        width: 330px;
        height: 194px;
        background-color: #fff;
        position: absolute;
        padding: 15px;
        color: #222222;
        box-sizing: border-box;
        z-index: 10;
        right: 100px;
        top: 70px;
        border: 1px solid #F6F7F9
    }

    .top-modal .admin-modal{
        right: 0;
        left: 565px;
        top: 50px;
    }

    .second .admin-modal{
        right: 300px;
    }

    .item__wrp-icons .admin-modal{
        top: 60px;
        right: 250px;
    }

    .admin-modal__wrp{
        display: flex;
        flex-direction: column;
        border-top: 2px solid #F5F6F8;
        max-height: 120px;
        overflow-y: auto;
    }

    .admin-modal__p{
        width: 100%;
        margin: 0;
        padding: 4px 15px;
        height: 36px;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        line-height: 26px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #BABCBF
    }

    .admin-modal__p svg{
        margin-left: auto;
    }

    .admin-modal__p input{
        font-family: Montserrat;
        border: none;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 40px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        cursor: pointer;
    }
    
    .modal__label:before{
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background-color:#9F9DFF;
        left: 0px;
        border-radius: 50%;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        padding-top: 10px;
        color: #BABCBF;
    }

    @media(max-width: 450px) {
        .admin-modal{
            width: 70vw;
        }
    }

    @media(max-width: 1403px){
        .first .admin-modal{
            top: 155px;
        }

        .second .admin-modal{
            top: 155px;
        }
    }

    @media(max-width:1199px){
        .first .admin-modal{
            top: 155px;
        }

        .second .admin-modal{
            top: 155px;
        }
    }

    @media(max-width:999px){
        .first .admin-modal{
            top: 195px;
        }

        .second .admin-modal{
            top: 195px;
            right: 400px;
        }
    }

    @media(max-width: 944px){
        .tracker-wrp__item .admin-modal{
            right: 40px;
        }

        .close .tracker-wrp__item .admin-modal{
            right: 250px;
        }
    }

    @media(max-width:800px){
        .second .admin-modal{
            right: 20px;
            top: 165px;
        }
    }

    
    @media(max-width: 744px){
        .tracker-wrp__item .admin-modal{
            right: 70px;
        }

        .close .tracker-wrp__item .admin-modal{
            right: 70px;
        }
    }

    @media(max-width:710px){
        .first .admin-modal{
            right: 0;
            left: 0;
        }
    }

    @media(max-width: 615px){
        .first .admin-modal{
            top: 205px;
        }

        .second .admin-modal{
            top: 205px;
        }

        .tracker-wrp__item .admin-modal{
            right: 20px;
        }

        .close .tracker-wrp__item .admin-modal{
            right: 20px;
        }
    }

    @media(max-width:480px){
        .second .admin-modal{
            right: 0;
        }
    }

</style>