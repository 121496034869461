import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useEntryStore = defineStore({
    id: 'EntryStore',
    state(){ 
        return {
            list: [],
            search: '',
            listVerions: 1,
            currentEntry: [],
            currentEntrySet: false,
            errors: [] 
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        isCurrentEntrySet(state){
            return state.currentEntrySet == true
        },
        entries: function(state) {
            let entries = new Object()
            
            var today = new Date()
            var todayDate = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2) +'-'+ ("0" + today.getDate()).slice(-2)

            let yesterday = new Date(new Date().setDate(new Date().getDate()-1))
            var yesterdayDate = yesterday.getFullYear()+'-'+("0" + (yesterday.getMonth() + 1)).slice(-2) + '-' + ("0" + yesterday.getDate()).slice(-2)
            
            const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

            for(let i=0; i<state.list.length; i++){
                let date = state.list[i].sd
                let dateStr = new Date(date).toLocaleDateString('en-us', options)
                if ( date == todayDate) dateStr = 'Today'
                if ( date == yesterdayDate) dateStr = 'Yesterday'
                
                if ( entries[date] == null )
                    entries[date] = { t: dateStr, uk: date, list: [] }
                
                entries[date].list.push(state.list[i])
            }
            let returnEntries = new Object()
            let entryKeys = Object.keys(entries).sort()
            for(let i=entryKeys.length - 1; i>=0; i--){
                returnEntries[entryKeys[i]] = entries[entryKeys[i]]
            }
            return returnEntries
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        setCurrentEntryStatus(suk, scs){
            this.$patch((state) => {
                state.currentEntry.suk = suk
                this.$patch((state) => {
                    for(let i=0;i<this.list.length;i++){
                        if ( state.list[i].tuk == state.currentEntry.tuk) {
                            state.list[i].suk = suk
                            state.list[i].scs = scs
                        }
                    }   
                })
            })
        },
        updateEntryStatus(tuk, suk, scs){
            this.$patch((state) => {
                this.$patch((state) => {
                    for(let i=0;i<state.list.length;i++){
                        if ( state.list[i].tuk == tuk) {
                            state.list[i].suk = suk
                            state.list[i].scs = scs
                        }
                    }  
                    state.listVerions++ 
                })
            })
        },
        setCurrentEntryDate(date){
            this.$patch((state) => {
                state.currentEntry.sd = this.getDateValue(date)
            })
        },
        setCurrentEntryStartTime(time){
            this.$patch((state) => {
                state.currentEntry.st = time
            })
        },
        setCurrentEntryEndTime(time){
            this.$patch((state) => {
                state.currentEntry.et = time
            })
        },
        setCurrentEntryBillable(billable){
            this.$patch((state) => {
                state.currentEntry.ib = billable
            })
        },
        setCurrentEntryTotalTime(totalTime){
            if ( totalTime.toString().split(':').length != 3 ) {
                totalTime = this.timeToStr(totalTime)
            }
            this.$patch((state) => {
                state.currentEntry.tt = totalTime
            })
        },
        async changeSearch(search){
            this.$patch((state) => {
                state.search = search;
            })
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get('ws/{wk}/entries');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        startEntry(item){
            this.$patch((state) => {
                state.currentEntry = Object.assign({}, item)
                state.currentEntry.sd = this.getDateValue(new Date())
                state.currentEntry.st = this.getTimeString(new Date())
                state.currentEntry.ed = this.getDateValue(new Date())
                state.currentEntry.et = this.getTimeString(new Date())
                state.currentEntrySet = true
            })
        },
        async addEntry(time) {
            await this.stopEntry(time)
        },
        async stopEntry(time){
            this.$patch((state) => {
                this.setCurrentEntryTotalTime(time)
                this.insert(state.currentEntry)
                state.currentEntry = []
                state.currentEntrySet = false
            })
        },
        async insert(dataInsert){            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/entries',
                         dataInsert
                    )
                    this.$patch((state) => {
                        state.list.unshift(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async duplicate(uk){            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    const response = await ApiCall.post(
                        'ws/{wk}/entries/' + uk + '/duplicate'
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(uk, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.post(
                    'ws/{wk}/entries/' + uk,
                    form
                )
                this.$patch((state) => {
                    for(let i=0;i<this.list.length;i++){
                        if ( state.list[i].uk == uk) {
                            state.list.splice(i, 1)
                            state.list.unshift(response.data.message)
                            break
                        }
                    }   
                })
                return response
            }
        },
        async remove(entry) {
            ApiCall.options().then(() => {
                ApiCall.delete('ws/{wk}/entries/' + entry.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    else {
                        this.$patch((state) => {
                            for(let i=0;i<this.list.length;i++){
                                if ( state.list[i].uk == entry.uk) {
                                    state.list.splice(i, 1)
                                    break
                                }
                            }   
                        })
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e);
                })
            }) 
        },
        getDateString(date) {
            let dateStr = ''
            var today = new Date()
            var todayDate = today.getFullYear()+'-'+("0" + (today.getMonth() + 1)).slice(-2) +'-'+ ("0" + today.getDate()).slice(-2)

            let yesterday = new Date(new Date().setDate(new Date().getDate()-1))
            var yesterdayDate = yesterday.getFullYear()+'-'+("0" + (yesterday.getMonth() + 1)).slice(-2) + '-' + ("0" + yesterday.getDate()).slice(-2)
            
            const options = { /*weekday: 'long', */year: 'numeric', month: 'short', day: 'numeric' };

            if ( date == todayDate) dateStr = 'Today'
            else if ( date == yesterdayDate) dateStr = 'Yesterday'
            else dateStr = new Date(date).toLocaleDateString('en-us', options)

            return dateStr
        },
        getTimeString(dateValue) {
            let date = new Date(dateValue)
            let timeStr = +("0" + date.getHours()).slice(-2)+':'+("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2)
            return timeStr
        },
        getDateValue(dateValue) {
            let date = new Date(dateValue)
            let dateStr = date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2)
            return dateStr
        },
        timeToStr(totalSeconds){
            let hours = Math.floor(totalSeconds / 3600)
            let minutes = Math.floor( ( totalSeconds - hours * 3600) / 60 )
            let seconds = totalSeconds - hours * 3600 - minutes * 60
            return ("0" + hours).slice(-2) + ':' + ("0" + minutes).slice(-2) + ':' + ("0" + seconds).slice(-2)
        },
        mergeAndAddTimeAndDate(date, time1, time2) {
            return this.addTimeAndDate(this.mergeTimeAndDate(date, time1), time2)
        },
        mergeDateAndSubstractTime(date, time1, time2) {
            return ((new Date(this.mergeTimeAndDate(date, time1))).getTime() - (new Date(this.mergeTimeAndDate(date, time2)).getTime()))/1000
        },
        mergeTimeAndDate(date, time){
            let hms = time.split(':')
            let newDate = new Date(date)
            if ( hms.length == 3 ) {
                let endTime =  newDate.getFullYear()+'-'+("0" + (newDate.getMonth() + 1)).slice(-2) + '-' + ("0" + newDate.getDate()).slice(-2) + ' '
                     + ("0" + (hms[0])).slice(-2) +':' + ("0" + (hms[1])).slice(-2) +':'+("0" + (hms[2])).slice(-2)
                    return endTime
                }
        },
        addTimeAndDate(date, time){
            let hms = time.split(':')
            let endTime =  ''
            if ( hms.length == 3 ) {
                endTime = (new Date(date)).getTime() + (parseInt(hms[0]) * 3600 + parseInt(hms[1]) * 60 + parseInt(hms[2])) * 1000
            }
            return endTime
        },
        subtractTime(date, time){
            let hms = time.split(':')
            let endTime =  ''
            if ( hms.length == 3 ) {
                endTime = (new Date(date)).getTime() - (parseInt(hms[0]) * 3600 + parseInt(hms[1]) * 60 + parseInt(hms[2])) * 1000
            }
            return endTime
        }
    }
})