<template>
        <div class="edit-tag-modal" v-click-outside="onClickOutside">
            <h3 class="edit-tag-modal--title">
                Add new tag
            </h3>
            <form class="edit-tag-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="edit-tag-modal__item">
                    <p class="edit-tag-modal__name">
                        <label for="">
                            Tag name
                        </label>
                        <input type="text" v-model="form['title']">
                    </p>
                </div>
                <div class="buttons">
                    <button class="edit-tag-modal__create"  @click="addMethod">
                        Add
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { useTagStore } from '@/store/TagStore';
    
    export default {
        props:['tagRow', 'editTitle'],
        data() {
            const tagStore = useTagStore();
            return {
                errors:[],
                form: {},
                store: tagStore
            };
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            
            addMethod() {
                this.store.insert(this.form).then(response => {
                    // JSON responses are automatically parsed.
                    if ( response.data.result=='ok')
                        this.$emit('close')//TODO: update table row
                    else
                        this.errors.push(response.data.message);
                })
                .catch(e => {
                    this.errors.push(e)
                })
            }
        }
    };
</script>

<style scoped>
    .edit-tag-modal{
        width: 549px;
        height: 250px;
        background-color: #fff;
        position: fixed;
        top: 30%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .edit-tag-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }


    .edit-tag-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .edit-tag-modal__item{
        display: flex;
        flex-direction: row;
    }

    .edit-tag-modal__name input{
        margin-top: 5px;
        border-radius: 30px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row-reverse;
    }

    .buttons button{
        margin-left: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .edit-tag-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .edit-tag-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

</style>