<template>
        <div class="top-modal">
            <p class="top-modal__select">
                <input class="modal__input" type="checkbox" id="select-all" v-model="isCheckedAll">
                <label class="modal__label" for="select-all">Select all</label>
            </p>
            <p class="top-modal__cancel" @click="isCheckedAll = false">
                    Cancel selection
            </p>
            <button class="tracker-info__tags" @click="showTagModal = !showTagModal"> 
                <p>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.2249 13.4978L19.9344 14.2026L19.2249 13.4978ZM13.5416 19.2187L12.8322 18.5139V18.5139L13.5416 19.2187ZM8.99493 19.2187L9.70436 18.5139L9.70436 18.5139L8.99493 19.2187ZM19.2249 8.9211L19.9344 8.21633L19.2249 8.9211ZM12.4823 2.13394L13.1918 1.42917V1.42917L12.4823 2.13394ZM2.1292 12.3076L1.41977 13.0124H1.41977L2.1292 12.3076ZM18.5155 12.793L12.8322 18.5139L14.251 19.9235L19.9344 14.2026L18.5155 12.793ZM12.8322 18.5139C11.9677 19.3841 10.5688 19.3841 9.70436 18.5139L8.2855 19.9235C9.93208 21.581 12.6045 21.581 14.251 19.9235L12.8322 18.5139ZM18.5155 9.62588C19.3836 10.4997 19.3836 11.9192 18.5155 12.793L19.9344 14.2026C21.5773 12.5488 21.5773 9.87014 19.9344 8.21633L18.5155 9.62588ZM11.7729 2.83872L18.5155 9.62588L19.9344 8.21633L13.1918 1.42917L11.7729 2.83872ZM11.7598 0.833252H3.36103V2.83325H11.7598V0.833252ZM9.70436 18.5139L2.83863 11.6028L1.41977 13.0124L8.2855 19.9235L9.70436 18.5139ZM0.833252 3.36103V11.5898H2.83325V3.36103H0.833252ZM2.83863 11.6028C2.83519 11.5994 2.83325 11.5947 2.83325 11.5898H0.833252C0.833252 12.1228 1.0441 12.6342 1.41977 13.0124L2.83863 11.6028ZM3.36103 0.833252C1.96498 0.833252 0.833252 1.96498 0.833252 3.36103H2.83325C2.83325 3.06955 3.06955 2.83325 3.36103 2.83325V0.833252ZM13.1918 1.42917C12.8129 1.04775 12.2974 0.833252 11.7598 0.833252V2.83325C11.7647 2.83325 11.7694 2.83522 11.7729 2.83872L13.1918 1.42917ZM9.08325 7.79158C9.08325 8.50495 8.50495 9.08325 7.79158 9.08325V11.0833C9.60952 11.0833 11.0833 9.60952 11.0833 7.79158H9.08325ZM7.79158 9.08325C7.07822 9.08325 6.49992 8.50495 6.49992 7.79158H4.49992C4.49992 9.60952 5.97365 11.0833 7.79158 11.0833V9.08325ZM6.49992 7.79158C6.49992 7.07822 7.07822 6.49992 7.79158 6.49992V4.49992C5.97365 4.49992 4.49992 5.97365 4.49992 7.79158H6.49992ZM7.79158 6.49992C8.50495 6.49992 9.08325 7.07822 9.08325 7.79158H11.0833C11.0833 5.97365 9.60952 4.49992 7.79158 4.49992V6.49992Z" fill="#F2F3F6"/>
                    </svg>
                </p>
            </button>
            <button class="tracker-info__calendar" @click="showCalendarModal = !showCalendarModal"> 
                <p>
                    <svg class="tracker-info__calendar"  width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.33333 0.916748V4.40936M13.6667 0.916748V4.58341M18.25 8.25008H1.75M3.58333 19.2501H16.4167C17.3759 19.2501 18.25 18.3372 18.25 17.4167V4.58341C18.25 3.66294 17.3759 2.75008 16.4167 2.75008H3.58333C2.6241 2.75008 1.75 3.66294 1.75 4.58341V17.4167C1.75 18.3372 2.6241 19.2501 3.58333 19.2501Z" stroke="#F2F3F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.91128 13.1666C7.49699 12.8014 6.86508 12.8412 6.49986 13.2555C6.13465 13.6698 6.17443 14.3017 6.58872 14.6669L7.91128 13.1666ZM9.14062 15.5834L8.47935 16.3336C8.87761 16.6846 9.48082 16.6633 9.85328 16.2849L9.14062 15.5834ZM13.4627 12.6183C13.8501 12.2247 13.8451 11.5915 13.4515 11.2041C13.0579 10.8167 12.4248 10.8216 12.0373 11.2152L13.4627 12.6183ZM6.58872 14.6669L8.47935 16.3336L9.8019 14.8333L7.91128 13.1666L6.58872 14.6669ZM9.85328 16.2849L13.4627 12.6183L12.0373 11.2152L8.42797 14.8819L9.85328 16.2849Z" fill="#F2F3F6"/>
                    </svg>
                </p>
            </button>
            <button class="tracker-info__member" @click="showAdminModal = !showAdminModal"> 
                <p>
                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3334 3.5V6.25M18.3334 6.25H21.0834M18.3334 6.25H15.5834M18.3334 6.25V9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10.8006 10.375H4.78274C2.64758 10.375 0.916687 12.2405 0.916687 14.5417C0.916687 15.7843 1.84143 16.7917 2.98216 16.7917H12.6012C13.7419 16.7917 14.6667 15.7843 14.6667 14.5417C14.6667 12.2405 12.9358 10.375 10.8006 10.375Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11 4.41659C11 6.1885 9.56362 7.62492 7.79171 7.62492C6.01979 7.62492 4.58337 6.1885 4.58337 4.41659C4.58337 2.64467 6.01979 1.20825 7.79171 1.20825C9.56362 1.20825 11 2.64467 11 4.41659Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
            </button>
                <AdminModal  v-if="showAdminModal"  @close="showAdminModal = false" />
                <TagModal  v-if="showTagModal"  @close="showTagModal = false" />
                <CalendarModal v-if="showCalendarModal"  @close="showCalendarModal = false"/>
        </div>
</template>

<script>
    import TagModal from '@/components/Modal/TagModal'
    import AdminModal from '@/components/Modal/AdminModal'
    import CalendarModal from '@/components/Modal/CalendarModal'

    export default {
        components: {
            TagModal, AdminModal, CalendarModal
        },
        data(){
            return{
                showTagModal: false,
                showAdminModal: false,
                showCalendarModal: false,
                isCheckedAll:'',
            }
        }
    }
</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .top-modal{
        width: 100vw;
        height: 57px;
        background: #2A2D34;
        position: fixed;
        top: 0;
        left: 0;
        color: #F2F3F6;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 300px;
        justify-content: flex-start;
        box-sizing: border-box;
        z-index: 15;
    }

    .tracker-info__member svg{
        stroke: #F2F3F6;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 25px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        cursor: pointer;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #606569;
        border-radius: 4px;
        top: 0;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check_bright.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .top-modal__select{
        margin-right: 30px;
    }

    .top-modal__cancel{
        text-decoration: underline;
        font-size: 14px;
        font-weight: 600;
        color:#F2F3F6;
        cursor: pointer;
        margin-right: 30px;
    }

    button p{
        height: 37px;
        border-right: 2px solid #606569;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .tracker-info__tags p{
        border-left: 2px solid #606569;
    }

    button{
        padding: 0;
    }

    @media(max-width: 1070px){
        .top-modal{
            padding: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }

    @media (max-width: 480px) {
        .top-modal{
            height: auto;
            padding: 10px 0;
            flex-wrap: wrap;
        }
        
        .top-modal__cancel{
            width: 100%;
            text-align: center;
            padding: 10px;
        }
    }

</style>