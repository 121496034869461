<template>
        <div class="status-modal" v-click-outside="onClickOutside">
            <p class="status-modal__p" >
                
                <input type="text" class="width-100-perc" v-model="search" placeholder="Find status"/>
                <svg v-if="showAddStatusButton" @click="$emit('openCreateStatus')" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.3333 10C17.3333 14.0501 14.0501 17.3334 9.99999 17.3334V19.3334C15.1546 19.3334 19.3333 15.1547 19.3333 10H17.3333ZM9.99999 17.3334C5.9499 17.3334 2.66666 14.0501 2.66666 10H0.666656C0.666656 15.1547 4.84533 19.3334 9.99999 19.3334V17.3334ZM2.66666 10C2.66666 5.94993 5.9499 2.66669 9.99999 2.66669V0.666687C4.84533 0.666687 0.666656 4.84536 0.666656 10H2.66666ZM9.99999 2.66669C14.0501 2.66669 17.3333 5.94993 17.3333 10H19.3333C19.3333 4.84536 15.1546 0.666687 9.99999 0.666687V2.66669Z" fill="#606569"/>
                    <path d="M8.99999 13.3334C8.99999 13.8856 9.44771 14.3334 9.99999 14.3334C10.5523 14.3334 11 13.8856 11 13.3334H8.99999ZM11 6.66669C11 6.1144 10.5523 5.66669 9.99999 5.66669C9.44771 5.66669 8.99999 6.1144 8.99999 6.66669H11ZM13.3333 11C13.8856 11 14.3333 10.5523 14.3333 10C14.3333 9.44774 13.8856 9.00002 13.3333 9.00002V11ZM6.66666 9.00002C6.11437 9.00002 5.66666 9.44774 5.66666 10C5.66666 10.5523 6.11437 11 6.66666 11V9.00002ZM11 13.3334V10H8.99999V13.3334H11ZM11 10V6.66669H8.99999V10H11ZM9.99999 11H13.3333V9.00002H9.99999V11ZM9.99999 9.00002H6.66666V11H9.99999V9.00002Z" fill="#606569"/>
                </svg>
            </p>
            <div class="status-modal__wrp" v-if="hasStatuses">
                <p class="modal__title">
                    Status
                </p>
                <div class="tag-list-item"  v-for="item in statusStore.statuses" :key="item.uk">
                    <input class="modal__input clickable-button" type="radio" :id="item.uk" :value="item.t" name="status" @click="changeTaskStatus(item)" :checked="item.uk == taskSuk">
                    <label class="modal__label clickable-button" :class="item.cssClass" :for="item.uk"> {{ item.t }}</label>
                </div>
            </div>
            <div class="status-modal__wrp" v-else>
                <p class="modal__title">
                    No matching status
                </p>
            </div>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { useStatusStore } from '@/store/StatusStore'
    import { useTaskStore } from '@/store/TaskStore'
    import { useEntryStore } from '@/store/EntryStore'


    export default {
        props: ['updateTaskStatus','tuk', 'taskTitle', 'taskSuk'],
        data() {
            const taskStore = useTaskStore()
            const statusStore = useStatusStore()
            const entryStore = useEntryStore()
            return {
                entryStore: entryStore,
                taskStore: taskStore,
                statusStore: statusStore,
                search: '',
                hasStatuses: true,
                showAddStatusButton: false
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        watch: {
            search(){
                this.statusStore.changeSearch(this.search)
            }
        },
        methods: {
            changeTaskStatus(item){
                this.taskStore.setTaskStatus(this.tuk, item.uk, item.cs)
                this.updateTaskStatus(this.tuk, item.uk, item.cs)
                this.$emit('close')
            },
            onClickOutside () {
                this.$emit('close')
            }
        },
        created() {
            this.statusStore.listing()
        }
    }
</script>

<style scoped>
    .status-modal{
        width: 330px;
        height: 160px;
        background-color: #fff;
        position: absolute;
        padding: 15px;
        color: #222222;
        box-sizing: border-box;
        z-index: 10;
        left: 0px;
        top: 75px;
        border: 1px solid #F6F7F9
    }

    .status-modal__wrp{
        display: flex;
        flex-direction: column;
        border-top: 2px solid #F5F6F8;
        background-color: white;
    }

    .status-modal__p{
        width: 100%;
        margin: 0;
        padding: 4px 15px;
        height: 36px;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        line-height: 26px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #BABCBF
    }

    .status-modal__p svg{
        margin-left: auto;
        cursor: pointer;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 40px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 18px;
        height: 18px;
        top: 0px;
        left: 0;
        background-image: url('../../assets/img/radio_pas.png');

    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/radio_act.png');
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .modal__label:after{
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background-color:#9F9DFF;
        left: 25px;
        top: 5px;
        border-radius: 50%;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        padding-top: 10px;
        color: #BABCBF;
    }

    @media(max-width: 450px) {
        .status-modal{
            width: 70vw;
        }
    }

    .tracker-wrp__item .status-modal{
        top: 50px;   
    }

</style>