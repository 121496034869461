<template>
    <section class="tracker-info">
        <div class="tracker-info__left">
            <button class="tracker-info__plus" @click="showStatusModalMethod">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="12" r="11" fill="#9CEDFF" fill-opacity="0.1" stroke="#9CEDFF" stroke-width="2"/>
                    <path d="M12 8V12M12 12H16M12 12H8M12 12V16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <button class="tracker-info__project" v-if="!projectSelected" @click="showSelectModal = !showSelectModal">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.13 9C15.13 12.5899 12.2199 15.5 8.63 15.5V17.5C13.3244 17.5 17.13 13.6944 17.13 9H15.13ZM8.63 15.5C5.04015 15.5 2.13 12.5899 2.13 9H0.130005C0.130005 13.6944 3.93558 17.5 8.63 17.5V15.5ZM2.13 9C2.13 5.41015 5.04015 2.5 8.63 2.5V0.5C3.93558 0.5 0.130005 4.30558 0.130005 9H2.13ZM8.63 2.5C12.2199 2.5 15.13 5.41015 15.13 9H17.13C17.13 4.30558 13.3244 0.5 8.63 0.5V2.5Z" fill="black"/>
                    <path d="M7.63 12C7.63 12.5523 8.07772 13 8.63 13C9.18229 13 9.63 12.5523 9.63 12H7.63ZM9.63 6C9.63 5.44772 9.18229 5 8.63 5C8.07772 5 7.63 5.44772 7.63 6H9.63ZM11.63 10C12.1823 10 12.63 9.55228 12.63 9C12.63 8.44772 12.1823 8 11.63 8V10ZM5.63 8C5.07772 8 4.63 8.44772 4.63 9C4.63 9.55228 5.07772 10 5.63 10V8ZM9.63 12V9H7.63V12H9.63ZM9.63 9V6H7.63V9H9.63ZM8.63 10H11.63V8H8.63V10ZM8.63 8H5.63V10H8.63V8Z" fill="black"/>
                </svg>
                <span>
                    Project
                </span>
            </button>
            <div :class="projectColorClass" v-if="projectSelected" class="task-project-subtitle item__info">
                <span class="task-title-span" v-if="entryStore.currentEntry.task_t">{{ entryStore.currentEntry.task_t }}</span>
            </div>
            <div class="tracker-info__div-auto">
                <input class="tracker-info__input" type="text" @keydown="changeAutocompleterSearchMethod" v-model="entryStore.currentEntry.t"  v-click-outside="onClickOutsideSearch"> 
                <div class="tracker-info__autocomplete" v-if="showAutocompleter">
                    <div class="tracker-info__autocomplete-item" v-for="item in taskStore.tasksAutocompleter" :key="item.title">
                        <p class="autocomplete__title clickable-button" @click="selectTaskFromAutocompleter(item)">{{item.project}}</p>
                        <p class="autocomplete__info clickable-button" @click="selectTaskFromAutocompleter(item)">{{item.task}}</p>
                        <p class="autocomplete__partners clickable-button" @click="selectTaskFromAutocompleter(item)">{{item.client}}</p>
                        <p class="autocomplete__tags clickable-button" @click="selectTaskFromAutocompleter(item)">
                            <span class="autocomplete__span" v-for="i in item.tags" :key="i">{{i}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="tracker-info__tabs">
            <TimeTrackerFirst  v-if="showFirst" ref="timeTrackerFirst" :stopTimerParent="stopTimer"/>
            <TimeTrackerSecond  v-if="!showFirst" ref="timeTrackerSecond" :afterEntryAdded="stopTimer"/>
            <div class="tracker-info__tabs-icons">
                <p @click="showFirst = true" v-bind:class="{activef: showFirst}">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.3334 10.0001C18.3334 14.6025 14.6024 18.3334 10 18.3334C5.39765 18.3334 1.66669 14.6025 1.66669 10.0001C1.66669 5.39771 5.39765 1.66675 10 1.66675C14.6024 1.66675 18.3334 5.39771 18.3334 10.0001Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 6.25V10L12.5 11.6667" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
                <p @click="showFirst = false" v-bind:class="{actives: !showFirst}">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.50002 1.33325H18.3334M1.66669 7.99992H3.33335M1.66669 14.6666H3.33335M1.66669 1.33325H3.33335M7.50002 7.99992H18.3334M7.50002 14.6666H18.3334" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </p>
            </div>
        </div>
        <CreateNewStatus v-if="showCreateNewStatus" @close="showCreateNewStatus = false"/>
        <div class="bg" v-if="showCreateNewStatus" @close="showCreateNewStatus = false"></div>
        <StatusModal  v-if="showStatusModal"  @close="showStatusModal = false" :tuk="getCurrentEntryTuk()" :taskTitle="getCurrentEntryTaskTitle()" :taskSuk="getCurrentEntrySuk" :updateTaskStatus="updateTaskStatus" @openCreateStatus="showCreateNewStatus = true"/>
        <SelectModal  v-if="showSelectModal" :selectCurrentTask="selectCurrentTask"  @close="showSelectModal = false" @openMenu="showCreateNewModal = true"/>
        <CreateNewModal v-if="showCreateNewModal" @close="showCreateNewModal = false"/>
        <div class="bg" v-if="showCreateNewModal" @close="showCreateNewModal = false"></div>
        <UserActionNotificationModal :message="notificationMessage" v-if="showUserNotificationModal" @close="showUserNotificationModal = false" />

    </section>
</template>

<script>

    import vClickOutside from 'v-click-outside'
    import SelectModal from '@/components/Modal/SelectModal'
    import StatusModal from '@/components/Modal/StatusModal'
    import CreateNewStatus from '@/components/Modal/CreateNewStatus'
    import CreateNewModal from '@/components/Modal/CreateNewModal'
    import TimeTrackerFirst from '@/components/TimeTracker/TimeTrackerFirst'
    import TimeTrackerSecond from '@/components/TimeTracker/TimeTrackerSecond'
    import UserActionNotificationModal from '@/components/Modal/UserActionNotificationModal'
    import { useEntryStore } from '@/store/EntryStore'
    import { useTaskStore } from '@/store/TaskStore'

    export default{  
        components: {
                SelectModal, StatusModal, CreateNewModal, TimeTrackerFirst, TimeTrackerSecond, CreateNewStatus, UserActionNotificationModal
            },
        data(){
            const entryStore = useEntryStore()
            const taskStore = useTaskStore()
            return {
                taskStore: taskStore,
                entryStore: entryStore,
                showCreateNewStatus: false,
                showCreateNewModal: false,
                showStatusModal: false,
                showSelectModal: false,      
                showAutocompleter: false,          
                showFirst: true,
                projectSelected: entryStore.isCurrentEntrySet,
                projectColorClass: 'item__info_' + entryStore.currentEntry.cs,
                notificationMessage: '',
                showUserNotificationModal: false,
                tasksAutocompleter: ''
            }
        },
        watch: {
            tasksAutocompleter(){
                this.taskStore.changeAutocompleterSearch(this.tasksAutocompleter) 
            }
        },
        created() {
            this.taskStore.listingAll().then(() => {
                
            })
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            getCurrentEntryTuk(){
                return this.entryStore.currentEntry.tuk
            },
            getCurrentEntryTaskTitle(){
                return this.entryStore.currentEntry.task_t
            },
            getCurrentEntrySuk(){
                return this.entryStore.currentEntry.suk
            },
            updateTaskStatus(tuk, suk, scs){
                this.entryStore.setCurrentEntryStatus(suk, scs)
                this.showUserNotificationModalMethod('Status for task ' + this.entryStore.currentEntry.task_t + ' was changed')
            },
            onClickOutsideSearch(){
                this.showAutocompleter = false
            },
            changeAutocompleterSearchMethod(el){
                this.tasksAutocompleter = el.target.value
                if ( !this.projectSelected ){
                    this.showAutocompleter = true
                }
            },
            selectTaskFromAutocompleter(item){
                this.showAutocompleter = false
                this.entryStore.startEntry( { t: item.task, tuk: item.uk, task_t: item.project + ' / '+ item.task, cs: item.cs, ib: item.idb, suk: item.suk, scs: item.scs } )
                this.startTimer()
            },
            updateTaskTitle(title){
                this.tasksAutocompleter = title
            },
            showStatusModalMethod(){
                if ( this.entryStore.currentEntrySet )
                    this.showStatusModal = !this.showStatusModal
                else {
                    this.showUserNotificationModalMethod('You need to set task first')
                }
            },
            showUserNotificationModalMethod(message){
                this.notificationMessage = message
                this.showUserNotificationModal = true
                setTimeout(this.closeUserNotificationModal, 2000);
            },
            closeUserNotificationModal(){
                this.showUserNotificationModal = false;
            },
            updateTotalTime(){
                let hms = this.totalTime.split(':')
                if ( hms.length == 3 )
                    this.endTime =  this.entryStore.getTimeString(
                        this.entryStore.addTimeAndDate( this.entryStore.mergeTimeAndDate(this.date,   this.startTime),
                            this.totalTime)
                    )
            },
            selectCurrentTask(task) {
                this.entryStore.startEntry( { t: task.t, tuk: task.uk, task_t: task.pt + ' / '+ task.t, cs: task.cs, ib: task.idb, suk: task.suk, scs: task.scs } )
                this.startTimer()
            },
            startTimer(){
                if ( this. showFirst ) {
                    this.$refs.timeTrackerFirst.startTimer()
                }
                this.updateCurrentEntry()
                this.tasksAutocompleter = ''
            },
            stopTimer(){
                this.projectSelected = false
            },
            updateCurrentEntry(){
                this.projectSelected = true
                this.projectColorClass = 'item__info_' + this.entryStore.currentEntry.cs
            }
        }
    }
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }

    button{
        cursor: pointer;
    }

    .item__info{
        padding-left: 25px;
        font-weight: 600;
        position: relative;
    }
    
    .item__info:after{
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        left: 10px;
        top: 15px;
        border-radius: 50%;
        border-left: 1px solid #F6F7F9;
    }
    div.task-project-subtitle {
        height: 42px;
        line-height: 42px;
        text-align: center;
    }
    div.task-project-subtitle > span.task-title-span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        
        font-style: italic;
        font-size: 0.8em;
        text-align: left;
        
        min-width: 150px;
    }

    .tracker-info{
        padding-left: 20px;
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
        background-color: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
        height: 82px;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .tracker-info__left{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
    }

    .tracker-info__tabs{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        background: #F0F1F4;
        height: 100%;
    }

    .tracker-info__plus{
        margin-right: 15px;
        cursor: pointer;
    }

    .tracker-info__project{
        width: 136px;
        height: 42px;
        background: rgba(156, 237, 255, 0.1);
        border: 2px solid #9CEDFF;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    .tracker-info__project span{
        margin-left: 9px;
    }

    .tracker-info__div-auto{
        flex-grow: 1;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        display: flex;
    }


    .tracker-info__input{
        width:  100%;
        border: 2px solid #E7E7E8;
        box-sizing: border-box;
        border-radius: 4px;
        height: 42px;
        min-width: 150px;
        padding: 12px 15px;
        margin-right: 15px;
        font-family: Montserrat;
        color: #222222;
    }

    .tracker-info__tabs-icons p{
        cursor: pointer;
    }

    .tracker-info__tab.first{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #fff;
        border-radius: 0 0 10px 0;
    }

    .base-timer__label{
        display: block;
        line-height: 83px;
        margin: 0 20px;
        font-size: 20px;
        font-weight: 600;
        width: 120px;
        text-align: center;

    }

    .first button{
        margin: 20px 0;
        border-right: 2px solid #F2F3F6;
    }

    .first .tracker-info__timer-btn{
        width: 91px;
        height: 42px;
        border-radius: 4px;
        border: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        align-self: center;
        margin-right: 40px;
    }

    .tracker-info__timer-btn--on{
        background: #9CEDFF;
        color: #222222;
    }

    .tracker-info__timer-btn--off{
        background: #FF3C3C;
        color: #F2F3F6;
    }

    .tracker-info__tabs-icons{
        width: 40px;
        height: 100%;
    }

    .tracker-info__tabs-icons p{
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .activef{
        background-color: #fff;
        border-radius: 0 0 10px 0;
    }

    .actives{
        background-color: #fff;
        border-radius: 0 10px 0 0;
    }

    .tracker-info__tabs-icons svg{
        stroke: #606569;
    }

    .activef svg{
        stroke: #222222;
    }

    .actives svg{
        stroke: #222222;
    }

    .tracker-info__tab.second{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #fff;
        border-radius: 0 10px 0 0;
    }

    .first button p{
        width: 42px;
        height: 42px;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
    }

    .first button:active p{
        background-color: #9CEDFF;
    }

    .first button:first-child svg{
        fill: #606569;
    }

    .first button:first-child:active svg{
        fill: #222222;
    }

    .first button:nth-child(n+2) svg{
        stroke: #606569;
    }

    .first button:nth-child(n+2):active svg{
        stroke: #222222;

    }

    .active{
        background-color: #9CEDFF;
    }

    .first  button:nth-child(2) .active svg{
        stroke: #222222;
    }

    .tracker-info__autocomplete{
        display: none;
        position: absolute;
        height: auto;
        background-color: #fff;
        padding: 10px;
        overflow-y: auto;
        z-index: 13;
    }

    /*.tracker-info__input:focus ~ */.tracker-info__autocomplete{
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        border-top: 0;
        top: 55px;
        box-shadow: 0px 0px 30px rgb(0 0 0 / 5%);
    }

    .tracker-info__autocomplete-item{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tracker-info__autocomplete-item{
        padding: 7px 0;
    }

    .autocomplete__title{
        font-size: 14px;
        font-weight: 500;
        padding-right: 15px;
        cursor: pointer;
    }

    .autocomplete__info{
        padding-left: 45px;
        font-size: 14px;
        font-weight: 600;
        position: relative;
    }

    .autocomplete__info:before{
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-color:#9DE8FF;
        left: 5px;
        border-radius: 50%;
    }
    
    .autocomplete__info:after{
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        background-color:#9F9DFF;
        left: 30px;
        top: 4px;
        border-radius: 50%;
    }

    .autocomplete__partners{
        padding-left: 15px;
        font-size: 14px;
        font-weight: 500;
        color: #606569;
    }

    .autocomplete__tags{
        padding-left: 10px;
    }

    .autocomplete__span{
        display: inline-block;
        padding: 5px 10px;
        background-color: #9CEDFF;
        box-sizing: border-box;
        border-radius: 4px;
        margin: 0 5px;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }




    @media(max-width: 1640px){
        .tracker-info__autocomplete-item{
            flex-wrap: wrap;
        }

        .autocomplete__tags{
            margin-left: auto;
        }
    }

    @media(max-width: 1403px){
        .tracker-info{
            flex-wrap: wrap;
            height: 164px;
        }

        .tracker-info__left{
            height: 50%;
        }

        .tracker-info__tabs{
            height: 50%;
            align-self: flex-end;
            justify-content: flex-end;
            width: 100%;
        }

        .tracker-info__div-auto{
            margin-right: 10px;
        }

        .tracker-info__tab{
            background-color: #fff;
            width: 100%;
        }
    }

    @media(max-width: 999px){
        .tracker-info{
            flex-wrap: wrap;
            height: 246px;
        }

        .tracker-info__left{
            height: 33%;
        }

        .tracker-info__tabs{
            height: 66%;
            align-self: flex-end;
            justify-content: flex-end;
            width: 100%;
        }

        .tracker-info__div-auto{
            margin-right: 10px;
        }

        .tracker-info__tabs-icons p{
            padding: 0 10px;
        }
    }

    @media(max-width: 615px) {
        .tracker-info{
            flex-wrap: wrap;
            height: 350px;
        }

        .tracker-info__tabs{
            height: 67%;
        }

        .tracker-info__left{
            height: 33%;
        }

        .tracker-info__left{
            flex-wrap: wrap;
        }

        .tracker-info__div-auto{
            margin-top: 10px;
        }

    }

    
    @media(max-width: 391px){
        .tracker-info{
            height: 385px;
            padding-left: 0;
        }

        .tracker-info__div-auto{
            margin-left: 10px;
        }
    }

</style>