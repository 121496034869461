<template>
    <div class="tracker-info__tab first">
        <button class="tracker-info__tags" @click="showTagModalMethod"> 
            <p>
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.225 13.4978L19.9345 14.2026L19.225 13.4978ZM13.5417 19.2187L12.8323 18.5139V18.5139L13.5417 19.2187ZM8.99502 19.2187L9.70445 18.5139L9.70445 18.5139L8.99502 19.2187ZM19.225 8.9211L19.9345 8.21633L19.225 8.9211ZM12.4824 2.13394L13.1919 1.42917V1.42917L12.4824 2.13394ZM2.12929 12.3076L1.41986 13.0124H1.41986L2.12929 12.3076ZM18.5156 12.793L12.8323 18.5139L14.2511 19.9235L19.9345 14.2026L18.5156 12.793ZM12.8323 18.5139C11.9678 19.3841 10.5689 19.3841 9.70445 18.5139L8.28559 19.9235C9.93217 21.581 12.6045 21.581 14.2511 19.9235L12.8323 18.5139ZM18.5156 9.62588C19.3837 10.4997 19.3837 11.9192 18.5156 12.793L19.9345 14.2026C21.5774 12.5488 21.5774 9.87014 19.9345 8.21633L18.5156 9.62588ZM11.773 2.83872L18.5156 9.62588L19.9345 8.21633L13.1919 1.42917L11.773 2.83872ZM11.7599 0.833252H3.36112V2.83325H11.7599V0.833252ZM9.70445 18.5139L2.83872 11.6028L1.41986 13.0124L8.28559 19.9235L9.70445 18.5139ZM0.833344 3.36103V11.5898H2.83334V3.36103H0.833344ZM2.83872 11.6028C2.83528 11.5994 2.83334 11.5947 2.83334 11.5898H0.833344C0.833344 12.1228 1.04419 12.6342 1.41986 13.0124L2.83872 11.6028ZM3.36112 0.833252C1.96507 0.833252 0.833344 1.96498 0.833344 3.36103H2.83334C2.83334 3.06955 3.06964 2.83325 3.36112 2.83325V0.833252ZM13.1919 1.42917C12.8129 1.04775 12.2975 0.833252 11.7599 0.833252V2.83325C11.7648 2.83325 11.7695 2.83522 11.773 2.83872L13.1919 1.42917ZM9.08334 7.79158C9.08334 8.50495 8.50504 9.08325 7.79168 9.08325V11.0833C9.60961 11.0833 11.0833 9.60952 11.0833 7.79158H9.08334ZM7.79168 9.08325C7.07831 9.08325 6.50001 8.50495 6.50001 7.79158H4.50001C4.50001 9.60952 5.97374 11.0833 7.79168 11.0833V9.08325ZM6.50001 7.79158C6.50001 7.07822 7.07831 6.49992 7.79168 6.49992V4.49992C5.97374 4.49992 4.50001 5.97365 4.50001 7.79158H6.50001ZM7.79168 6.49992C8.50504 6.49992 9.08334 7.07822 9.08334 7.79158H11.0833C11.0833 5.97365 9.60961 4.49992 7.79168 4.49992V6.49992Z"/>
                </svg>
            </p>
        </button>
        <button class="tracker-info__dollar" @click="isDollarMethod"> 
            <p v-bind:class="{active: isDollar}">
                <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5833 4.58341H4.70833C2.93642 4.58341 1.5 6.01983 1.5 7.79175C1.5 9.56366 2.93642 11.0001 4.70833 11.0001H9.29167C11.0636 11.0001 12.5 12.4365 12.5 14.2084C12.5 15.9803 11.0636 17.4167 9.29167 17.4167H2.41667M7 0.916748V21.0834" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </p>
        </button>
        <button class="tracker-info__member" @click="showAdminModalMethod"> 
            <p>
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.3334 3.5V6.25M18.3334 6.25H21.0834M18.3334 6.25H15.5834M18.3334 6.25V9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.8006 10.375H4.78274C2.64758 10.375 0.916687 12.2405 0.916687 14.5417C0.916687 15.7843 1.84143 16.7917 2.98216 16.7917H12.6012C13.7419 16.7917 14.6667 15.7843 14.6667 14.5417C14.6667 12.2405 12.9358 10.375 10.8006 10.375Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 4.41659C11 6.1885 9.56362 7.62492 7.79171 7.62492C6.01979 7.62492 4.58337 6.1885 4.58337 4.41659C4.58337 2.64467 6.01979 1.20825 7.79171 1.20825C9.56362 1.20825 11 2.64467 11 4.41659Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </p>
        </button>
        <p @click="showStartTimeModal = !showStartTimeModal" id="base-timer-label" class="base-timer__label">{{hours}} : {{minutes}} : {{seconds}} </p>
        <button class="tracker-info__timer-btn tracker-info__timer-btn--on" v-if="timerOn"  @click="startTimer">Start</button>
        <button class="tracker-info__timer-btn tracker-info__timer-btn--off" v-else  @click="stopTimer">Stop</button>
        <AdminModal  v-if="showAdminModal && entryStore.currentEntrySet"  @close="showAdminModal = false" />
        <TagModal  v-if="showTagModal && entryStore.currentEntrySet" :task_uk="entryStore.currentEntry.tuk" :task_t="entryStore.currentEntry.task_t" @close="showTagModal = false" />
        <StartTimeModal  v-if="showStartTimeModal && entryStore.currentEntrySet" :updateStartTime="updateStartTime" @close="showStartTimeModal = false" />
        
        <UserActionNotificationModal :message="notificationMessage" v-if="showUserNotificationModal" @close="showUserNotificationModal = false" />
    </div>
</template>

<script>
    import TagModal from '@/components/Modal/TagModal'
    import AdminModal from '@/components/Modal/AdminModal'
    import StartTimeModal from '@/components/Modal/StartTimeModal'
    import UserActionNotificationModal from '@/components/Modal/UserActionNotificationModal'
    import { useEntryStore } from '@/store/EntryStore'




export default{  
    props: ['stopTimerParent'],
    components: {
        TagModal, AdminModal, StartTimeModal, UserActionNotificationModal
    },
    data(){
        const entryStore = useEntryStore()
        return {
            entryStore: entryStore,
            isDollar: entryStore.currentEntry.ib ? entryStore.currentEntry.ib == 1: false,
            showTagModal: false,
            showAdminModal: false,    
            showStartTimeModal: false,   
            showFirst: true,
            timePassed: 0,
            hours: '00',
            minutes: '00',
            seconds: '00',
            timerOn: true,
            stopTime: 0,
            startTimeValue: null,
            currentTime: 0,
            timer: null,
            showUserNotificationModal: false,
            notificationMessage: ''
        }
    },
    watch: {
        isDollar() {
            this.entryStore.setCurrentEntryBillable( this.isDollar ? 1 : 0 )
        }
    },
    methods:{
        showTagModalMethod(){
            if ( this.entryStore.currentEntrySet )
                this.showTagModal = !this.showTagModal
            else {
                this.showUserNotificationModalMethod('You need to set task first')
            }
        },
        isDollarMethod(){
            if ( this.entryStore.currentEntrySet )
                this.isDollar = !this.isDollar
            else {
                this.showUserNotificationModalMethod('You need to set task first')
            }
        },  
        showAdminModalMethod(){
            if ( this.entryStore.currentEntrySet )
                this.showAdminModal = !this.showAdminModal
            else {
                this.showUserNotificationModalMethod('You need to set task first')
            }
        },
        showUserNotificationModalMethod(message){
            this.notificationMessage = message
            this.showUserNotificationModal = true
            setTimeout(this.closeUserNotificationModal, 1000);
        },
        closeUserNotificationModal(){
            this.showUserNotificationModal = false;
        },
        updateStartTime(time){
            this.currentTime = this.entryStore.mergeDateAndSubstractTime(this.entryStore.currentEntry.sd, this.entryStore.getTimeString(new Date()), time)
            this.formatTime(this.currentTime)
        },
        formatTime(time){
            this.hours = Math.floor(time / 3600)
            this.minutes = Math.floor((time - this.hours*3600) / 60)
            this.seconds = parseInt(time % 60);
            if (this.seconds < 10) {
                this.seconds = `0${this.seconds}`
            }
            if (this.minutes < 10){
                this.minutes = `0${this.minutes}`
            }
            if (this.hours < 10){
                this.hours = `0${this.hours}`
            }
        },
        startTimer() {
            this.isDollar = this.entryStore.currentEntry.ib ? this.entryStore.currentEntry.ib == 1: false
            if ( this.timer != null ) clearTimeout(this.timer)
            this.startTimeValue = new Date()
            this.timer = setInterval(() => {
                this.currentTime = Math.abs(new Date() - this.startTimeValue)/1000
                this.formatTime(this.currentTime);
                }, 1000)
            this.timerOn = false
        },
        stopTimer() {
            this.entryStore.stopEntry(this.currentTime)
            this.currentTime = 0
            this.startTimeValue = null
            this.hours = this.minutes = this.seconds = '00'
            clearTimeout(this.timer)
            this.timerOn = true
            this.stopTimerParent()
        },
        stopOnlyTimer() {
            clearTimeout(this.timer)
            this.timerOn = true
        },
    },
    destroyed() {
        this.stopOnlyTimer()
    },
}
</script>

<style scoped>
    p{
        margin: 0;
        padding: 0;
    }

    button{
        cursor: pointer;
    }


    .tracker-info__tab.first{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        background-color: #fff;
        border-radius: 0 0 10px 0;
    }

    .base-timer__label{
        display: block;
        line-height: 83px;
        margin: 0 20px;
        font-size: 20px;
        font-weight: 600;
        width: 120px;
        text-align: center;
        cursor: pointer;
    }

    .first button{
        margin: 20px 0;
        border-right: 2px solid #F2F3F6;
    }

    .first .tracker-info__timer-btn{
        width: 91px;
        height: 42px;
        border-radius: 4px;
        border: 0;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        align-self: center;
        margin-right: 40px;
    }

    .tracker-info__timer-btn--on{
        background: #9CEDFF;
        color: #222222;
    }

    .tracker-info__timer-btn--off{
        background: #FF3C3C;
        color: #F2F3F6;
    }


    .first button p{
        width: 42px;
        height: 42px;
        line-height: 55px;
        text-align: center;
        border-radius: 50%;
    }

    .first button:active p{
        background-color: #9CEDFF;
    }

    .first button:first-child svg{
        fill: #606569;
    }

    .first button:first-child:active svg{
        fill: #222222;
    }

    .first button:nth-child(n+2) svg{
        stroke: #606569;
    }

    .first button:nth-child(n+2):active svg{
        stroke: #222222;
    }

    .active{
        background-color: #9CEDFF;
    }

    .first  button:nth-child(2) .active svg{
        stroke: #222222;
    }

    @media(max-width: 1199px){
        div.tracker-info__tab.first{
            width: 100%;
            justify-content: flex-end;
        }
    }

    
    @media(max-width: 999px){
        div.tracker-info__tab.first{
            width: 100%;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-items: center;
        }

        .second button.tracker-info__member{
            border-right: 0;
        }
    }
</style>