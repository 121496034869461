import { defineStore } from 'pinia'
import Vue from 'vue'
import { ApiCall } from '@/store/ApiCall'

export const useStatusStore = defineStore({
    id: 'StatusStore',
    state() { 
        return {
            list: [],
            search: '',
            errors: [] 
        }
    },
    getters:{
        count: function(state){
            return state.list.length
        },
        statuses: function(state){
            return state.list.filter((el) => { 
                el.cssClass = 'item__info_'+el.cs
                el.isChecked = false
                return el.t.toLowerCase().indexOf(state.search.toLowerCase()) != -1});
        },
        searchValue: function(state){
            return state.search
        }
    },
    actions:{
        async changeSearch(search){
            this.$patch((state) => {
                state.search = search;
            })
        },
        async listing(){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText.toLowerCase() == 'ok') {
                try  {
                    const response = await ApiCall.get(
                        'ws/{wk}/statuses');
                    
                    if ( response.data.result=='ok') {
                        this.$patch((state) => {
                            state.list = response.data.message
                        })
                    }
                    else {
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    }
                }
                catch (error) {
                    this.$patch((state) => {
                        state.errors.push(error);
                    })
                }
            } else {
                this.$patch((state) => {
                    state.errors.push(optionsData.message)
                })
            }
        },
        
        async insert(dataInsert){
            // data should contain title field
            if ( dataInsert['title'].length == 0) return;
            console.log(dataInsert['title'])
            
            try{
                const optionsData = await ApiCall.options()
                if ( optionsData.statusText == 'OK') {
                    console.log('Insert Method: ' + dataInsert.title)
                    const response = await ApiCall.post(
                        'ws/{wk}/statuses',
                        dataInsert
                    )
                    this.$patch((state) => {
                        state.list.push(response.data.message)
                    })
                    return response
                }
            }catch(error){
                this.$patch((state) => {
                    state.errors.push(error);
                })
            }
        },
        async update(status, form){
            const optionsData = await ApiCall.options()
            if ( optionsData.statusText == 'OK') {
                const response = await ApiCall.put(
                    'ws/{wk}/statuses/' +status.uk,
                    form
                )
                for(let i=0;i<this.list.length;i++){
                    if ( this.list[i].uk == status.uk) {
                        this.$patch((state) => {
                            state.list[i].t = form.title
                        })
                        break
                    }
                }
                return response
            }
        },
        async remove(status) {
            ApiCall.options().then(response => {
                ApiCall.delete('ws/{wk}/statuses/' + status.uk)
                .then(response => {
                    if ( response.data.result!='ok')
                        this.$patch((state) => {
                            state.errors.push(response.data.message);
                        })
                    else {
                        for(let i=0;i<this.list.length;i++){
                            if ( this.list[i].uk == status.uk) {
                                this.$patch((state) => {
                                    state.list.splice(i, 1);  
                                })
                                break
                            }
                        }
                    }
                })
                .catch(e => {
                    this.errors.push(e)
                })
            })
            .catch(e => {    
                this.$patch((state) => {
                    state.errors.push(e)
                })
            })
        }
    }
})