<template>
    <section class="tracker-wrp">
        <TopModal v-if="showTopModal" />
        <div class="tracker-wrp__top">
            <p class="tracker-wrp__title" v-if="groupByDate">This week</p>
            <div class="tracker-wrp__buttons">
                <button class="button__add" @click="showAddTaskModal = !showAddTaskModal">[+] Add new task</button>
                <p @click="groupByDate = !groupByDate">
                    <button class="button__group button__group--date" v-if="groupByDate">Group by date</button>
                    <button class="button__group button__group--status" v-else>Group by status</button>
                </p>
            </div>
        </div>
        <div class="tracker-wrp__tasks-wrp" @click="isOpenTopModal">
            <div class="tracker-wrp__block" 
                        v-for="itemDate in entryStore.entries"
                        :key="itemDate.uk" >
                <p class="tracker-wrp__block-title" v-if="groupByDate">{{ itemDate.t }}</p>
                <p class="tracker-wrp__block-title group-by-status" v-else>Task is set</p>
                <div class="tracker-wrp__item-wrp"
                    v-for="item in itemDate.list"
                    :key="item.uk">
                    <TimeTrackerItem :item="item" :scs="item.scs" :showTopMenu="showTopMenu" :isChecked="item.isChecked" :updateTimeEntryList="updateTimeEntryList" :startNewTimer="startNewTimer" @showTopMenu="isOpenTopModal(item.uk)"/>
                </div>
            </div> 
        </div>

        <AddTaskModal v-if="showAddTaskModal"  @close="showAddTaskModal = false"/>
        <div class="bg" v-if="showAddTaskModal" @close="showAddTaskModal = false"></div>
    </section>
</template>

<script>
    import AddTaskModal from '@/components/Modal/AddTaskModal'
    import TimeTrackerItem from '@/components/TimeTracker/TimeTrackerItem'
    import TopModal from '@/components/Modal/TopModal'
    import { useProjectStore } from '@/store/ProjectStore'
    import { useEntryStore } from '@/store/EntryStore'
    import { EventBus } from '@/store/EventBus'

    export default {
        props: ['startFirstTimer'],
        data() { 
            const projectStore = useProjectStore()
            const entryStore = useEntryStore()
            return {
                projectStore: projectStore,
                entryStore: entryStore,
                groupByDate: true,
                showAddTaskModal: false,
                showFirst: true,
                showTopModal: false,
                timeEntries: [],
                isBulkSelectModalOpen: false,
            }
        },
        methods: {
            showTopMenu(showTopMenu){
                this.showTopModal = showTopMenu
            },
            updateTimeEntryList(){
                this.$forceUpdate()
            },
            startNewTimer(item){
                this.entryStore.startEntry(item)
                this.startFirstTimer()
                this.$forceUpdate()
            },
            isOpenTopModal(){
                this.isBulkSelectModalOpen = true
            },
            isOpenTopModalt1(){
                this.t1.isChecked = !this.t1.isChecked
            },
            isOpenTopModalt2(){
                this.t2.isChecked = !this.t2.isChecked
            },
            isOpenTopModalt3(){
                this.t3.isChecked = !this.t3.isChecked
            },
            isOpenTopModalt4(){
                this.t4.isChecked = !this.t4.isChecked
            },
            isOpenTopModalt5(){
                this.t5.isChecked = !this.t5.isChecked
            },
            isOpenTopModalt6(){
                this.t6.isChecked = !this.t6.isChecked
            }/*,
            isOpenTopModal(){
                if( this.t1.isChecked ||  this.t2.isChecked || this.t3.isChecked || this.t4.isChecked || this.t5.isChecked || this.t6.isChecked){
                    this.showTopModal = true;
                } else {
                    this.showTopModal = false;
                }
            }*/
        },
        created() {
            this.entryStore.listing().then(() => {
                this.timeEntries = this.entryStore.entries
            })
        },
        components: {
            AddTaskModal, TimeTrackerItem, TopModal
        }
    }


    const wlTimeTrackerHandler = function() {
        const entryStore = useEntryStore()
        entryStore.listing()
        EventBus.$off('click', wlTimeTrackerHandler);
    }

    EventBus.$on('click', wlTimeTrackerHandler);

</script>

<style scoped>

    p{
        margin: 0;
        padding: 0;
    }

    .tracker-wrp__top{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
    }

    .tracker-wrp__title{
        font-size: 16px;
        font-weight: 600;
        color: #606569;
    }

    .tracker-wrp__buttons{
        margin-left: auto;
        display: flex;
        flex-direction: row;
    }

    .button__add{
        font-size: 14px;
        font-weight: 600;
        color: #222222;
        margin-right: 15px;
        display: none;
        cursor: pointer;
    }

    .button__group{
        background: rgba(156, 237, 255, 0.1);
        border: 2px solid #9CEDFF;
        box-sizing: border-box;
        border-radius: 4px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        height: 37px;
        padding: 0 20px;
        cursor: pointer;
    }

    .tracker-wrp__top:hover .button__add{
        display: inline-block;
    }

    .tracker-wrp__block{
        margin-left: 32px;
    }

    .tracker-wrp__item-wrp{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #F6F7F9;
        box-sizing: border-box;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.03);
    }

    .tracker-wrp__block-title{
        font-size: 14px;
        font-weight: 600;
        color: #606569;
        padding: 10px;
    }

    .status .tracker-wrp__block-title{
        padding-bottom: 8px;
    }

    button{
        cursor: pointer;
    }

    .tracker-wrp__block:first-child{
        margin-top: 10px;
    }

    .tracker-wrp__block:nth-child(n+2){
        margin-top: 30px;
    }

    .group-by-status{
        background-color: #fff;
    }

    .status:nth-child(n+2){
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);
    }

    .status:nth-child(n+2) .tracker-wrp__item-wrp{
        box-shadow: none;
    }

    .status:first-child .group-by-status{
        border-bottom: 2px solid #D2B44A;
    }

    .status:nth-child(2) .group-by-status{
        border-bottom: 2px solid #9DE8FF;
    }

    .status:nth-child(3) .group-by-status{
        border-bottom: 2px solid #37F182;
    }

    @media(max-width: 800px){
        .button__add{
            display: inline-block;
        }
    }

    @media(max-width: 490px){
        .tracker-wrp__buttons{
            flex-direction: column-reverse;
            justify-content: flex-end;
        } 

        .button__add{
            margin-top: 10px;
        }
    }
</style>